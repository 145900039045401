import React from 'react'

import { Banner, Link } from '@buffer-mono/popcorn'
import { getPublishUrl } from '../../../../common/utils/urls'
import { useCrossAppNavigate } from '../../../../common/hooks/useCrossAppNavigate'
import useDismissBanner, {
  BannerTypes,
} from '../../../../common/hooks/useDismissBanner'

const TFABanner = (): JSX.Element | null => {
  const navigateCrossApp = useCrossAppNavigate()
  const { onDismissBanner, isActive } = useDismissBanner({
    banner: BannerTypes.tfaBanner,
  })

  if (!isActive) {
    return null
  }

  return (
    <Banner variant="info" onDismiss={onDismissBanner}>
      <Banner.Text>
        <strong>Set up two-factor authentication:</strong> For added security
        and easier logins, we recommend setting up a two-factor authentication.{' '}
        <Link
          color="inherit"
          target="_blank"
          href="https://support.buffer.com/article/503-enabling-two-factor-authentication"
        >
          Learn more
        </Link>
      </Banner.Text>
      <Banner.Action
        onClick={(): void => {
          navigateCrossApp(getPublishUrl('settings'))
        }}
      >
        Set up now
      </Banner.Action>
    </Banner>
  )
}

export default TFABanner
