import { Temporal, toTemporalInstant } from '@js-temporal/polyfill'
import { useCallback } from 'react'
import type { DragEndEvent } from '@dnd-kit/core'

import { toast } from '@buffer-mono/popcorn'

import type { CalendarPostCard_PostFragment } from '~publish/gql/graphql'
import { format, getZonedNow, isBefore } from '~publish/helpers/temporal'
import { CURRENT_TIME_ZONE } from '~publish/helpers/dateFormatters'
import { useDropPostMutation } from '~publish/pages/Channel/QueueList/useDropPostMutation'
import { useSwapPostsMutation } from '~publish/pages/Channel/QueueList/useSwapPostsMutation'

import { useUpdatePostDueAt } from './useUpdatePostDueAt'

const FIVE_MINUTES_MS = 5 * 60 * 1000

type UseHandleDragEndOptions = {
  posts: CalendarPostCard_PostFragment[]
  preserveTime: boolean
  timezone: string
  is24HourFormat: boolean
  onMovingToCustomSchedule?: (
    previousDueAt: string,
    newDueAt: string,
  ) => Promise<boolean>
}

/**
 * A custom hook that returns a function to handle the end of a drag event for calendar posts.
 *
 * @param {Object} options - The options for configuring the drag end behavior.
 * @param {Array<FragmentType<typeof CalendarPostCard_Post>>} options.posts - An array of post objects, each containing fragment data from CalendarPostCard_Post.
 * @param {boolean} options.preserveTime - Determines whether to preserve the original time when rescheduling. If true, only the date will change; if false, both date and time will be updated.
 * @param {string} options.timezone - The timezone to use for date calculations and formatting.
 * @param {boolean} options.is24HourFormat - Whether to use 24-hour time format (true) or 12-hour format (false) for time display.
 * @param {Function} options.onMovingToCustomSchedule - A function that is called when a post is moved to a custom schedule.
 *
 * @returns {(result: DragEndEvent) => void} A function that handles the end of a drag event.
 *   This function updates the due date of a post when it's dragged to a new position in the calendar.
 *
 * @example
 * const handleDragEnd = useHandleDragEnd({
 *   posts: data?.posts.edges?.map((edge) => getFragmentData(CalendarPostCard_Post, edge.node)) ?? [],
 *   preserveTime: viewMode === 'month',
 *   timezone,
 *   is24HourFormat: hasTwentyFourHourTimeFormat,
 * });
 *
 * // Later in your component:
 * <Calendar
 *   // ... other props
 *   onDragEnd={handleDragEnd}
 * />
 */
export const useHandleDragEnd = ({
  posts,
  preserveTime,
  timezone = CURRENT_TIME_ZONE,
  is24HourFormat = true,
  onMovingToCustomSchedule,
}: UseHandleDragEndOptions): ((result: DragEndEvent) => void) => {
  const { updatePostDueAt } = useUpdatePostDueAt()
  const [dropPost] = useDropPostMutation()
  const [swapPosts] = useSwapPostsMutation()

  const handleDragEnd = useCallback(
    async ({ active, over }: DragEndEvent): Promise<void> => {
      const isSlotDrop = over?.data?.current?.type === 'slot'
      const isPostDrop = over?.data?.current?.type === 'post'
      const isPostDrag = active.data.current?.type === 'post'
      const post = posts.find((post) => post.id === active.id.toString())

      if (
        !over ||
        !active ||
        (active.data.current?.timestamp === over.data.current?.timestamp &&
          !isSlotDrop &&
          post?.isCustomScheduled)
      ) {
        return
      }

      if ((isPostDrop && !isPostDrag) || (isSlotDrop && !isPostDrag)) {
        toast.error('This element cannot be dropped here')
        return
      }

      // Post dropped on Slot (Pin Post)
      if (isSlotDrop && isPostDrag && over.data.current?.timestamp) {
        const draggedPostId = active.id as string
        const newSlotDate = new Date(over.data.current.timestamp).toISOString()

        const dropPostResult = await dropPost({
          variables: { input: { id: draggedPostId, dueAt: newSlotDate } },
        })

        if (dropPostResult.success) {
          toast.success('Post rescheduled successfully')
        } else {
          toast.error(
            `Failed to reschedule post, ${dropPostResult.error.message}`,
          )
        }
      }

      // Post dropped on Post (Swap Posts)
      if (active.id !== over.id && isPostDrop) {
        const draggedPostId = active.id as string
        const overPostId = over.id as string

        const swapPostsResult = await swapPosts({
          variables: {
            input: { sourceId: draggedPostId, targetId: overPostId },
          },
        })

        if (swapPostsResult.success) {
          toast.success('Posts swapped successfully')
        } else {
          toast.error(swapPostsResult.error.message)
        }
      }

      // Post dropped on Hour or Day (Reschedule Post)
      if (!isSlotDrop && !isPostDrop) {
        const post = posts.find((post) => post.id === active.id.toString())
        if (!post || !post.dueAt) return

        const dueAt = getNewTimestamp(
          post.dueAt,
          over.data.current?.timestamp,
          preserveTime,
          timezone,
        )

        const isMovingToCustomSchedule = !post.isCustomScheduled

        if (isMovingToCustomSchedule) {
          const shouldContinue = await onMovingToCustomSchedule?.(
            post.dueAt,
            dueAt,
          )
          if (!shouldContinue) return
        }

        try {
          await updatePostDueAt({ id: post.id, dueAt, isPinned: false })
          toast.success(
            getSuccessMessage(
              dueAt,
              timezone,
              post,
              is24HourFormat,
              isMovingToCustomSchedule,
            ),
          )
        } catch (error) {
          toast.error(getErrorMessage(error))
        }
      }
    },
    [
      dropPost,
      swapPosts,
      posts,
      preserveTime,
      onMovingToCustomSchedule,
      updatePostDueAt,
      timezone,
      is24HourFormat,
    ],
  )

  return handleDragEnd
}

function getNewTimestamp(
  previousDueAt: string,
  toDateMilliseconds: number,
  preserveTime: boolean,
  timezone: string,
): string {
  if (!preserveTime) {
    return new Date(toDateMilliseconds).toISOString()
  }

  const fromDate =
    Temporal.Instant.from(previousDueAt).toZonedDateTimeISO(timezone)
  const toDateDate =
    Temporal.Instant.fromEpochMilliseconds(
      toDateMilliseconds,
    ).toZonedDateTimeISO(timezone)

  const newDateTime = fromDate.with({
    year: toDateDate.year,
    month: toDateDate.month,
    day: toDateDate.day,
  })

  const now = getZonedNow(newDateTime.timeZoneId)

  if (isBefore(newDateTime, now)) {
    return new Date(
      now.toInstant().epochMilliseconds + FIVE_MINUTES_MS,
    ).toISOString()
  }

  return new Date(newDateTime.toInstant().epochMilliseconds).toISOString()
}

function getErrorMessage(error: unknown): string {
  const maybeErrorMessage = (error as Error).message
  const fallbackErrorMessage =
    'Whoops, we had some problems updating the date for that post!'
  const message = maybeErrorMessage ?? fallbackErrorMessage
  return message
}

function getSuccessMessage(
  dueAt: string,
  timezone: string,
  post: CalendarPostCard_PostFragment,
  is24HourFormat: boolean,
  isMovingToCustomSchedule = false,
): string {
  const date = new Date(dueAt)
  const instant = toTemporalInstant.call(date)
  const timeZonedDate = instant.toZonedDateTimeISO(timezone)
  const type = ['draft', 'needs_approval'].includes(post.status)
    ? 'draft'
    : 'post'
  const formattedTime = format(
    timeZonedDate,
    `eee, MMMM d 'at' ${is24HourFormat ? 'HH:mm' : 'h:mm a'}`,
  )
  const message = `Great! We’ve rescheduled your ${type} for ${formattedTime}`
  if (isMovingToCustomSchedule) {
    return `${message}. Queue will be recalculated shortly.`
  }
  return message
}
