import React from 'react'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'motion/react'

import {
  Avatar,
  EllipsisIcon,
  Flex,
  IconButton,
  Separator,
  SimpleSpinner,
  Skeleton,
  SkeletonText,
  StatusDot,
  toast,
} from '@buffer-mono/popcorn'

import type { Channel, CommentData, Post } from '~publish/gql/graphql'

import { useDismissComment } from '../hooks/useDismissComment'
import { useUndismissComment } from '../hooks/useUndismissComment'
import { useReplyToComment } from '../hooks/useReplyToComment'
import { CommentReplyForm } from './CommentReplyForm'
import type { FilterStatus } from '../CommentsFilters/useFilters'
import { PostMiniature } from '../PostMiniature'
import { CommentMedia } from '../CommentMedia'
import { useCommentContext } from '../hooks/useCommentContext'
import { CommentHeader } from './CommentHeader'
import { CommentActions } from './CommentActions'
import { LastAuthorReply } from './LastAuthorReply'

import styles from './CommentItem.module.css'
import { CommentText } from './CommentText'

const CONTEXT_COMMENTS_ANIMATION = {
  initial: { height: 0, opacity: 0 },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      height: { duration: 0.2, ease: 'easeOut' },
      opacity: { duration: 0.2 },
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      height: { duration: 0.2, ease: 'easeIn' },
      opacity: { duration: 0.2 },
    },
  },
} as const

export const CommentItemSkeleton = (): JSX.Element => {
  return (
    <article className={styles.container}>
      <section className={styles.body}>
        <Skeleton rounded width={36} height={36} />
        <div className={styles.bodyWrapper}>
          <Flex align="center" gap="xs">
            <SkeletonText lines={1} width="6ch" />
            <SkeletonText lines={1} width="6ch" />
          </Flex>
          <Flex direction="column" gap="md">
            <SkeletonText lines={3} width="100%" />
          </Flex>
        </div>
      </section>
      <Skeleton width="160px" height="75px" />
    </article>
  )
}

export const CommentWrapperSkeleton = (): JSX.Element => {
  return (
    <>
      <div className={styles.wrapper}>
        <CommentItemSkeleton />
      </div>
      <Separator className={styles.separator} />
    </>
  )
}

const VerticalLine = (): JSX.Element => {
  return <div className={styles.verticalLine} />
}

const AvatarSection = ({
  hasParentComment,
  isContextComment,
  avatar,
  username,
  isNewComment,
}: {
  hasParentComment: boolean
  isContextComment: boolean
  avatar: string
  username: string
  isNewComment: boolean
}): JSX.Element => {
  return (
    <Flex
      direction="column"
      className={hasParentComment ? styles.expandableComment : undefined}
    >
      <div className={styles.avatarContainer}>
        {isNewComment && <StatusDot className={styles.newCommentStatusDot} />}
        <Avatar
          size="small"
          src={avatar}
          alt={username}
          className={styles.avatar}
        />
      </div>
      {isContextComment && <VerticalLine />}
    </Flex>
  )
}

const ExpandContextButton = React.forwardRef<
  HTMLButtonElement,
  {
    onClick: () => void
    loading: boolean
  }
>(({ onClick, loading }, ref) => {
  return (
    <IconButton
      ref={ref}
      label="View context"
      variant="tertiary"
      className={styles.contextButton}
      onClick={onClick}
      disabled={loading}
    >
      {loading ? <SimpleSpinner size="small" /> : <EllipsisIcon />}
    </IconButton>
  )
})

ExpandContextButton.displayName = 'ExpandContextButton'

const CommentItemContainer = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement> & {
    comment: CommentData
    isContextComment?: boolean
    hasParentComment?: boolean
    children: React.ReactNode
  }
>(
  (
    {
      className,
      comment,
      isContextComment,
      hasParentComment,
      children,
      ...props
    },
    ref,
  ): JSX.Element => {
    return (
      <article
        ref={ref}
        key={comment.id}
        className={clsx(
          styles.container,
          isContextComment && styles.contextComment,
          !isContextComment && !hasParentComment && styles.onlyComment,
          className,
        )}
        data-testid="comment-item"
        data-comment-id={comment.id}
        aria-label={`Comment from ${comment.author.username} ${
          comment.isOwnReply ? '(your reply)' : ''
        } ${
          comment.status === 'replied'
            ? 'replied to'
            : comment.status === 'dismissed'
            ? 'dismissed'
            : 'needs attention'
        }`}
        {...props}
      >
        {children}
      </article>
    )
  },
)
CommentItemContainer.displayName = 'CommentItemContainer'

type CommentItemProps = {
  comment: CommentData
  channel: Channel
  currentStatusFilter?: FilterStatus
  isContextComment?: boolean
  setShouldAnimateOut: (shouldAnimateOut: boolean) => void
}

export const CommentItem = React.forwardRef<HTMLDivElement, CommentItemProps>(
  (
    {
      comment,
      channel,
      currentStatusFilter,
      isContextComment = false,
      setShouldAnimateOut,
    },
    ref,
  ): JSX.Element => {
    const textareaRef = React.useRef<HTMLTextAreaElement>(null)
    const { dismissComment } = useDismissComment()
    const { undismissComment } = useUndismissComment()
    const { replyToComment, loading: isSubmittingForm } = useReplyToComment()

    const handleSubmitReply = async (text: string): Promise<void> => {
      try {
        await replyToComment(comment, text)
        if (currentStatusFilter === 'unreplied') {
          setShouldAnimateOut(true)
        }
      } catch (error) {
        console.error('Error sending reply:', error)
      }
    }

    const handleDismissComment = async (): Promise<void> => {
      if (currentStatusFilter === 'unreplied') {
        setShouldAnimateOut(true)
      }
      await dismissComment(comment.id)
    }

    const handleUndoDismissComment = async (): Promise<void> => {
      if (currentStatusFilter === 'dismissed') {
        setShouldAnimateOut(true)
      }
      await undismissComment(comment.id)
    }

    const hasAssets = comment.assets.length > 0
    const showAssets = !!hasAssets && !!comment.assets?.[0]
    const showReplyForm =
      !isContextComment &&
      comment.status !== 'dismissed' &&
      comment.status !== 'replied'
    const hasParentComment = comment.parentId !== null

    return (
      <CommentItemContainer
        key={comment.id}
        ref={ref}
        comment={comment}
        isContextComment={isContextComment}
        hasParentComment={hasParentComment}
      >
        <section className={styles.body}>
          <AvatarSection
            hasParentComment={hasParentComment}
            isContextComment={isContextComment}
            avatar={comment.author.avatar}
            username={comment.author.username}
            isNewComment={comment.status === 'new'}
          />
          <div className={styles.bodyWrapper}>
            <Flex align="center" gap="xs" justify="between">
              <CommentHeader
                isOwnReply={comment.isOwnReply}
                author={comment.author}
                publishedAt={comment.publishedAt}
              />
              <CommentActions
                status={comment.status}
                onDismiss={handleDismissComment}
                onUndoDismiss={handleUndoDismissComment}
                disabled={isSubmittingForm || isContextComment}
              />
            </Flex>
            <Flex direction="column" gap="md">
              {comment.text && (
                <CommentText
                  text={comment.text}
                  serviceType={comment.serviceType}
                />
              )}
              {showAssets && <CommentMedia assets={comment.assets} />}
              {showReplyForm && (
                <CommentReplyForm
                  id={`${comment.id}-reply`}
                  channel={channel}
                  onSubmit={handleSubmitReply}
                  textareaRef={textareaRef}
                  isSubmitting={isSubmittingForm}
                  initialExpanded={false}
                />
              )}
              {comment.lastReply && (
                <LastAuthorReply reply={comment.lastReply} />
              )}
            </Flex>
          </div>
        </section>
        {!isContextComment && (
          <PostMiniature
            post={comment.post as Post}
            condensed={false}
            className={styles.miniature}
          />
        )}
      </CommentItemContainer>
    )
  },
)

CommentItem.displayName = 'CommentItem'

export const CommentWrapper = React.forwardRef<
  HTMLLIElement,
  {
    comment: CommentData
    channel: Channel
    currentStatusFilter?: FilterStatus
    isFirstComment: boolean
  }
>((props, ref): JSX.Element => {
  const { comment, channel, currentStatusFilter, isFirstComment } = props
  const [shouldAnimateOut, setShouldAnimateOut] = React.useState(false)
  const { getCommentContext } = useCommentContext()
  const hasParentComment = comment.parentId !== null
  const [isLoadingContext, setIsLoadingContext] = React.useState(false)
  const [contextComments, setContextComments] = React.useState<CommentData[]>(
    [],
  )
  const [showContext, setShowContext] = React.useState(false)

  const handleExpandContext = async (): Promise<void> => {
    if (showContext) {
      setShowContext(false)
      return
    }

    setIsLoadingContext(true)
    try {
      const { context } = await getCommentContext(comment.id)
      setContextComments(context)
      setShowContext(true)
    } catch (error) {
      console.error('Error fetching comment context:', error)
      toast.error('Failed to load comment context')
    } finally {
      setIsLoadingContext(false)
    }
  }

  const showContextButton = hasParentComment && !showContext
  const showContextComments = showContext && contextComments.length > 0

  return (
    <AnimatePresence
      mode="sync"
      initial={false}
      onExitComplete={(): void => setShouldAnimateOut(false)}
    >
      <motion.li
        key={comment.id}
        ref={ref}
        animate={{
          opacity: shouldAnimateOut ? 0 : 1,
          height: shouldAnimateOut ? 0 : 'auto',
        }}
        exit={{ height: 0, opacity: 0 }}
        transition={{ type: 'spring', stiffness: 400, damping: 30, mass: 1 }}
        layout
      >
        <div
          className={clsx(
            styles.wrapper,
            isFirstComment && styles.firstComment,
          )}
        >
          {showContextButton && (
            <ExpandContextButton
              onClick={handleExpandContext}
              loading={isLoadingContext}
            />
          )}
          {showContextComments && (
            <motion.div
              {...CONTEXT_COMMENTS_ANIMATION}
              layout
              style={{ overflow: 'hidden' }}
            >
              {contextComments.map((contextComment, index) => (
                <CommentItem
                  key={`context-${comment.id}-${contextComment.id}-${index}`}
                  comment={contextComment}
                  channel={channel}
                  currentStatusFilter={currentStatusFilter}
                  setShouldAnimateOut={setShouldAnimateOut}
                  isContextComment={true}
                />
              ))}
            </motion.div>
          )}
          <CommentItem
            key={`comment-${comment.id}`}
            comment={comment}
            channel={channel}
            currentStatusFilter={currentStatusFilter}
            setShouldAnimateOut={setShouldAnimateOut}
          />
        </div>
        <Separator className={styles.separator} />
      </motion.li>
    </AnimatePresence>
  )
})

CommentWrapper.displayName = 'CommentWrapper'
