import React from 'react'
import { useDispatch } from 'react-redux'
import Joyride, {
  ACTIONS,
  EVENTS,
  STATUS,
  type CallBackProps,
  type Step,
} from 'react-joyride'

import { useLocalStorage } from '@buffer-mono/popcorn'

import { useChannels } from '~publish/components/PublishSidebar/useChannels'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import {
  allChannels,
  channel,
  channelSettings,
  getMatch,
  newCalendarAllChannels,
  newCalendarSingleChannel,
} from '~publish/legacy/routes'
import { usePostComposer } from '~publish/hooks/usePostComposer'

import { DialogTourIntro } from './DialogTourIntro'
import { StepCoachmark } from './StepCoachmark'

export enum TourStep {
  ALL_CHANNELS_SIDEBAR = 0,
  CALENDAR = 1,
  TAGS_FILTER = 2,
}

export const CHANNEL_TOUR_STORAGE_KEY = 'preference:publish-revamp-page-tour'
export enum TourStorageStep {
  INTRO = '-1', // initial state after closing the dialog
  REDIRECTED = 'redirected', // redirected to all channels
  CLOSED = 'closed', // finished the tour
  STEP_0 = '0',
  STEP_1 = '1',
  STEP_2 = '2',
}

export const steps: Step[] = [
  {
    target: '[data-tour-id="all-channels-menu-item"]',
    title: 'All your posts, one place',
    content: 'Access all of your posts from a single view.',
    disableBeacon: true,
    placement: 'right',
    offset: 0,
    data: {
      image:
        'https://buffer-publish.s3.amazonaws.com/images/channels-tour-sidebar.png',
    },
  },
  {
    target: '[data-tour-id="calendar"]',
    title: 'List or Calendar? You decide',
    content: 'Switch back and forth seamlessly and keep your filters applied.',
    disableBeacon: true,
    placement: 'bottom',
    offset: 0,
  },
  {
    target: '[data-tour-id="tags-filter"]',
    title: 'Narrow in with Tags',
    content: 'Use the new Tag filter to search your content faster.',
    disableBeacon: true,
    placement: 'bottom',
    offset: 0,
  },
]

const isAllChannelsPage = (): boolean => {
  const allChannelsMatch = getMatch({
    pathname: location.pathname,
    route: allChannels.route,
  })
  return !!allChannelsMatch
}

const isSingleChannelPage = (): boolean => {
  const singleChannelMatch = getMatch({
    pathname: location.pathname,
    route: channel.route,
  })
  return !!singleChannelMatch
}

const isCalendarInSingleChannelView = (): boolean => {
  const singleChannelCalendarMatch = getMatch({
    pathname: location.pathname,
    route: newCalendarSingleChannel.route,
  })
  return !!singleChannelCalendarMatch
}

const isCalendarInAllChannelsView = (): boolean => {
  const allChannelsCalendarMatch = getMatch({
    pathname: location.pathname,
    route: newCalendarAllChannels.route,
  })
  return !!allChannelsCalendarMatch
}

const isChannelSettingsPage = (): boolean => {
  const channelSettingsMatch = getMatch({
    pathname: location.pathname,
    route: channelSettings.route,
  })
  return !!channelSettingsMatch
}

export const ChannelTour = (): JSX.Element => {
  const dispatch = useDispatch()
  const { channels } = useChannels()

  const [channelTourCurrentStep, setChannelTourCurrentStep] = useLocalStorage(
    CHANNEL_TOUR_STORAGE_KEY,
    TourStorageStep.INTRO,
  )
  const guide = useDismissableBanner('publish-revamp-page-tour')

  const { isOpen: isPostComposerOpen } = usePostComposer()

  const [isDialogOpen, setDialogOpen] = React.useState(true)
  const [run, setRun] = React.useState(false)
  const [isPaused, setIsPaused] = React.useState(false)
  const [stepIndex, setStepIndex] = React.useState(
    parseInt(channelTourCurrentStep, 10),
  )

  React.useEffect(() => {
    if (channelTourCurrentStep === 'redirected') {
      setTimeout(() => {
        setRun(true)
        setStepIndex(0)
        setChannelTourCurrentStep(TourStorageStep.STEP_0)
      }, 800)
    }
  }, [channelTourCurrentStep, setChannelTourCurrentStep])

  React.useEffect(() => {
    const currentStep = parseInt(channelTourCurrentStep, 10)
    if (currentStep >= 0) {
      setRun(true)
      setDialogOpen(false)
    }
  }, [channelTourCurrentStep])

  const resetTour = (): void => {
    setChannelTourCurrentStep(TourStorageStep.CLOSED)
    setRun(false)
    setStepIndex(-1)
    guide.dismiss()
  }

  const handleTourStart = (): void => {
    const isChannelPage =
      isAllChannelsPage() ||
      isSingleChannelPage() ||
      isCalendarInSingleChannelView() ||
      isCalendarInAllChannelsView()
    const isSettingsPage = isChannelSettingsPage()

    setDialogOpen(false)

    if (isChannelPage && !isSettingsPage) {
      setRun(true)
      setChannelTourCurrentStep(TourStorageStep.STEP_0)
    } else {
      setChannelTourCurrentStep(TourStorageStep.REDIRECTED)
      dispatch(allChannels.goTo())
    }
  }

  const handleDialogClose = (): void => {
    setDialogOpen(false)
    resetTour()
  }

  const joyrideCallback = (data: CallBackProps): void => {
    const { action, index, status, type, size } = data

    if (type === EVENTS.TOUR_STATUS && status === STATUS.RUNNING) {
      setIsPaused(false)
    }

    if (
      action === ACTIONS.CLOSE ||
      status === STATUS.FINISHED ||
      status === STATUS.SKIPPED
    ) {
      resetTour()
      setIsPaused(false)
      return
    }

    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      const isLastStep = index === size - 1

      if (isLastStep && action === ACTIONS.NEXT) {
        // This is the last step and the user clicked "Next"
        resetTour()
        setIsPaused(false)
      } else {
        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
        setStepIndex(nextStepIndex)
        setChannelTourCurrentStep(nextStepIndex.toString() as TourStorageStep)
        setIsPaused(false)
      }
    }
  }

  const handleOverlayClick = (e: React.MouseEvent): void => {
    e.stopPropagation()
    setIsPaused(true)
  }

  const handleOverlayKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.stopPropagation()
      setIsPaused(true)
    }
  }

  const handleResumeTour = (): void => {
    setIsPaused(false)
  }

  if (
    channels.length === 0 ||
    !guide.isActive ||
    // do not show the tour if composer open
    isPostComposerOpen
  ) {
    return <></>
  }

  return (
    <>
      {channelTourCurrentStep === TourStorageStep.INTRO && isDialogOpen && (
        <DialogTourIntro
          open={isDialogOpen}
          onDialogClose={handleDialogClose}
          onTourStart={handleTourStart}
        />
      )}
      <Joyride
        steps={steps}
        continuous
        showSkipButton
        run={run}
        stepIndex={stepIndex}
        callback={joyrideCallback}
        disableOverlayClose
        disableOverlay
        disableScrolling
        disableScrollParentFix
        tooltipComponent={(props): JSX.Element =>
          isPaused ? (
            <StepCoachmark
              {...props}
              isPaused={isPaused}
              handleResumeTour={handleResumeTour}
            />
          ) : (
            <StepCoachmark
              {...props}
              open
              isPaused={isPaused}
              handleResumeTour={handleResumeTour}
            />
          )
        }
        styles={{
          options: {
            arrowColor: 'transparent',
            overlayColor: 'transparent',
          },
          tooltip: {
            padding: 0, // Remove padding to align with CoachMark
          },
        }}
      />
      {run && !isPaused && (
        <div
          id="tour-overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9996,
            cursor: 'pointer',
            pointerEvents: 'auto',
          }}
          onClick={handleOverlayClick}
          onKeyDown={handleOverlayKeyDown}
          tabIndex={0}
          role="button"
          aria-label="Pause tour"
        />
      )}
    </>
  )
}
