import { useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import gql from 'graphql-tag'

import { DISMISS_BANNER } from '../graphql/account'

export const BannerTypes = {
  emailVerification: 'emailVerification',
  facebookGroupNotifications: 'facebookGroupNotifications',
  streakCoachMark: 'streakCoachMark',
  xMigrationBanner: 'xMigrationBanner',
  tfaBanner: 'tfaBanner',
}

export const GetDismissedBanners = gql`
  query GetDismissedBanners {
    account {
      id
      dismissedBanners
    }
  }
`

type UseDismissBannerReturn = {
  onDismissBanner: () => void
  isActive: boolean
}

const useDismissBanner = ({
  banner,
}: {
  banner: string
}): UseDismissBannerReturn => {
  const { data, refetch } = useQuery(GetDismissedBanners, {
    fetchPolicy: 'cache-first',
  })
  const [dismissBanner] = useMutation(DISMISS_BANNER, {
    variables: { banner },
    update: (cache) => {
      if (!data?.account) {
        return
      }

      const account = data.account

      // optimistic response
      cache.writeQuery({
        query: GetDismissedBanners,
        data: {
          account: {
            ...account,
            dismissedBanners: [...(account?.dismissedBanners ?? []), banner],
          },
        },
      })
    },
  })
  const dismissedBanners = data?.account?.dismissedBanners
  const isActive = !!dismissedBanners && !dismissedBanners.includes(banner)

  const onDismissBanner = useCallback(async (): Promise<void> => {
    if (!banner || !Object.values(BannerTypes).includes(banner)) {
      return
    }

    const response = await dismissBanner({ variables: { banner } })

    if (response.data?.dismissBanner.__typename !== 'DismissBannerResponse') {
      // refetch to get the latest dismissed banners after successful response
      refetch()
    }
  }, [dismissBanner, refetch, banner])

  // memoize the banner object to avoid unnecessary changes
  // when used in other hooks/components
  return useMemo(
    () => ({ onDismissBanner, isActive }),
    [isActive, onDismissBanner],
  )
}

export default useDismissBanner
