import type { PayloadAction, Slice, SliceCaseReducers } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { InstagramAuthMethod, Service } from '../channelConnections/types'

export interface IReducerState {
  selectedService: Service | null
  isRefreshingChannelConnection: boolean
  startConnection: boolean
  startConversion?: boolean
  customChannelIdToConvert?: string
  authMethod?: InstagramAuthMethod
  connectionCompleted: boolean
  connectedChannelIds: string[] | null
  destinationUrl?: string
  cta?: string
  name?: string
  selectedRefreshChannelId?: string
  connectChannelUpgrade: boolean
  authRedirectUrl?: string
  showChannelStorefront: boolean
  serviceRequiresFurtherConfiguration: boolean
  skipCelebrationModal?: boolean
  prefillDataAfterConnection?: boolean
  skipChannelCheck?: boolean
  isSettingPostingGoal?: boolean
}

export const initialState: IReducerState = {
  selectedService: null,
  startConnection: false,
  startConversion: false,
  customChannelIdToConvert: undefined,
  authMethod: undefined,
  isRefreshingChannelConnection: false,
  connectionCompleted: false,
  connectedChannelIds: null,
  destinationUrl: undefined,
  cta: undefined,
  name: undefined,
  selectedRefreshChannelId: undefined,
  connectChannelUpgrade: false,
  authRedirectUrl: undefined,
  showChannelStorefront: false,
  serviceRequiresFurtherConfiguration: false,
  skipCelebrationModal: false,
  prefillDataAfterConnection: false,
  skipChannelCheck: false,
  isSettingPostingGoal: false,
}

export const channelConnectionsSlice: Slice<
  IReducerState,
  SliceCaseReducers<IReducerState>,
  'channelConnections'
> = createSlice({
  name: 'channelConnections',
  initialState,
  reducers: {
    setIsRefreshingChannelConnection: (
      state,
      action: PayloadAction<{
        selectedRefreshChannelId?: string
        name: string
        authMethod?: InstagramAuthMethod
        skipChannelCheck?: boolean
      }>,
    ) => {
      state.isRefreshingChannelConnection = true
      state.selectedRefreshChannelId = action.payload.selectedRefreshChannelId
      state.name = action.payload.name
      state.authMethod = action.payload.authMethod
      state.skipChannelCheck = action.payload.skipChannelCheck
    },
    setStartConnection: (
      state,
      action: PayloadAction<{
        selectedService: Service
        destinationUrl?: string
        cta?: string
        serviceRequiresFurtherConfiguration: boolean
        authMethod?: InstagramAuthMethod
        skipCelebrationModal?: boolean
        prefillDataAfterConnection?: boolean
      }>,
    ) => {
      state.startConnection = true
      state.selectedService = action.payload.selectedService
      state.destinationUrl = action.payload.destinationUrl
      state.cta = action.payload.cta
      state.showChannelStorefront = false
      state.authMethod = action.payload.authMethod
      state.serviceRequiresFurtherConfiguration =
        action.payload.serviceRequiresFurtherConfiguration
      state.skipCelebrationModal = action.payload.skipCelebrationModal
      state.prefillDataAfterConnection =
        action.payload.prefillDataAfterConnection
    },
    setStartConversion: (
      state,
      action: PayloadAction<{
        selectedService: Service
        customChannelIdToConvert: string
        cta?: string
        authMethod?: InstagramAuthMethod
      }>,
    ) => {
      state.startConversion = true
      state.selectedService = action.payload.selectedService
      state.customChannelIdToConvert = action.payload.customChannelIdToConvert
      state.authMethod = action.payload.authMethod
      state.cta = action.payload.cta
    },
    setAuthRedirectUrl: (
      state: IReducerState,
      action: PayloadAction<{
        url: string
      }>,
    ) => {
      state.authRedirectUrl = action.payload.url
    },
    setConnectedChannelIds: (
      state: IReducerState,
      action: PayloadAction<{
        channelIds: string[]
      }>,
    ) => {
      state.connectedChannelIds = action.payload.channelIds
    },
    connectionSuccess: (state: IReducerState) => {
      state.connectionCompleted = true
    },
    resetChannelConnectionsState: (state: IReducerState) => {
      state.selectedService = null
      state.startConnection = false
      state.startConversion = false
      state.customChannelIdToConvert = undefined
      state.authMethod = undefined
      state.isRefreshingChannelConnection = false
      state.connectionCompleted = false
      state.connectedChannelIds = null
      state.destinationUrl = undefined
      state.cta = undefined
      state.selectedRefreshChannelId = undefined
      state.connectChannelUpgrade = false
      state.authRedirectUrl = undefined
      state.showChannelStorefront = false
      state.serviceRequiresFurtherConfiguration = false
      state.skipCelebrationModal = false
      state.prefillDataAfterConnection = false
    },
    setConnectChannelUpgrade: (state: IReducerState) => {
      state.connectChannelUpgrade = true
    },
    setShowChannelStorefront: (
      state: IReducerState,
      action: PayloadAction<{
        cta: string
        prefillDataAfterConnection: boolean
        skipCelebrationModal: boolean
        selectedService?: Service
        isSettingPostingGoal?: boolean
      }>,
    ) => {
      state.selectedService = action.payload.selectedService || null
      state.startConnection = false
      state.isRefreshingChannelConnection = false
      state.selectedRefreshChannelId = undefined
      state.authRedirectUrl = undefined
      state.serviceRequiresFurtherConfiguration = false
      state.showChannelStorefront = true
      state.cta = action.payload.cta
      state.skipCelebrationModal = action.payload.skipCelebrationModal
      state.prefillDataAfterConnection =
        action.payload.prefillDataAfterConnection
      state.isSettingPostingGoal = action.payload.isSettingPostingGoal
    },
  },
})

export const {
  setStartConnection,
  setStartConversion,
  setIsRefreshingChannelConnection,
  setConnectedChannelIds,
  connectionSuccess,
  resetChannelConnectionsState,
  setConnectChannelUpgrade,
  setAuthRedirectUrl,
  setShowChannelStorefront,
} = channelConnectionsSlice.actions

export default channelConnectionsSlice.reducer
