import { useQuery } from '@apollo/client'
import { useRef } from 'react'
import { type FragmentOf, graphql } from '~publish/graphql'

import { sanitizeNullableArray } from '~publish/helpers/typeGuards'
import { useAppSelector } from '~publish/legacy/store'

export const ChannelNavChannelFragment = graphql(`
  fragment ChannelNavChannelFragment on Channel @_unmask {
    id
    name
    service
    avatar
    timezone
    serverUrl
    locationData {
      location
    }
    isLocked
    isDisconnected
    isQueuePaused
    apiVersion
  }
`)

export const GetSidebarInfo = graphql(
  `
    query GetSidebarInfo {
      # TODO: channels should be a top-level query
      account {
        id
        currentOrganization {
          id
          channels(product: publish) {
            id
            name
            service
            avatar
            ...ChannelNavChannelFragment
          }
        }
      }
    }
  `,
  [ChannelNavChannelFragment],
)

export type ChannelNavChannel = FragmentOf<typeof ChannelNavChannelFragment>

const FIVE_SECONDS = 5 * 1_000

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function useChannels() {
  const lastFetchTime = useRef(0)
  const { data, loading, ...rest } = useQuery(GetSidebarInfo, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy(_, { reason, initialFetchPolicy }) {
      // When variables change, the default behavior is to reset
      // options.fetchPolicy to context.initialFetchPolicy.
      if (reason === 'variables-changed') {
        return initialFetchPolicy
      }

      // Force fetching from cache if the last fetch was less than 5 seconds ago
      if (Date.now() - lastFetchTime.current < FIVE_SECONDS) {
        lastFetchTime.current = Date.now()
        return 'cache-only'
      }

      return initialFetchPolicy
    },
  })

  lastFetchTime.current = Date.now()

  const profilesIdOrder =
    useAppSelector((state) =>
      state.profileSidebar.profiles?.map((profile) => profile.id),
    ) ?? []

  const channels = sanitizeNullableArray(
    data?.account?.currentOrganization?.channels,
  ).sort((a, b) => {
    // TODO: Since we don't have order implemented on the GraphQL API,
    // we sort by the order of the profiles in the sidebar stored in Redux.
    // This should go away once we have order implemented on the API.
    const aIndex = profilesIdOrder.indexOf(a.id)
    const bIndex = profilesIdOrder.indexOf(b.id)
    if (aIndex === -1) {
      return 1
    }
    if (bIndex === -1) {
      return -1
    }
    return aIndex - bIndex
  })

  return {
    channels,
    loading,
    ...rest,
  }
}
