import React, { useEffect, useMemo } from 'react'

import { useSplitEnabled } from '@buffer-mono/features'

import TwitterIsDownBanner from '../TwitterIsDownBanner/TwitterIsDownBanner'
import PaymentPastDueBanner from '../PaymentPastDueBanner/PaymentPastDueBanner'
import EmailVerificationLinkRequestBanner from '../EmailVerificationLinkRequestBanner/EmailVerificationLinkRequestBanner'
import ChannelConnectionUpgradeBanner from '../ChannelConnectionUpgradeBanner/ChannelConnectionUpgradeBanner'
import { shouldShowPaymentPastDueBanner } from '../../utils/shouldShowPaymentPastDueBanner'
import { useUser } from '../../../../common/context/User'
import { trackBannerViewed } from '../../../../common/hooks/useSegmentTracking'
import { shouldShowMissingBillingAddressBanner } from '../../utils/shouldShowMissingBillingAddressBanner'
import MissingBillingAddress from '../MissingBillingAddress/MissingBillingAddressBanner'
import ExpiredCardBanner from '../ExpiredCardBanner/ExpiredCardBanner'
import { shouldShowExpiredCardBanner } from '../../utils/shouldShowExpiredCardBanner'
import TrialBanner from '../TrialBanner'
import { FacebookGroupsNotificationsBanner } from '../FacebookGroupsNotificationsBanner'
import {
  hasEditPermission,
  hasPaymentDetails,
  isOnActiveTrial,
  isOneBufferOrganization,
} from '../../../../common/utils/user'
import { shouldShowFacebookGroupsNotificationsBanner } from '../../utils/shouldShowFacebookGroupsNotificationBanner'
import TeamMemberOrgDowngradedBanner from '../TeamMemberOrgDowngradedBanner/TeamMemberOrgDowngradedBanner'
import XMigrationBanner from '../XMigrationBanner/XMigrationBanner'
import { shouldShowXMigrationBanner } from '../../utils/shouldShowXMigrationBanner'
import TFABanner from '../TFABanner'
import { GetAccountTFA } from '../../../../common/graphql/account'
import { useQuery } from '@apollo/client'
import { shouldShowTFABanner } from '../../utils/shouldShowTFABanner'

const BannerContainer = (): JSX.Element => {
  const user = useUser()
  const { data: accountTFAData } = useQuery(GetAccountTFA)

  const showTFABanner = shouldShowTFABanner({
    createdAt: accountTFAData?.account.createdAt,
    hasTFAEnabled: Boolean(accountTFAData?.account?.tfa?.type),
    user,
  })
  const showPaymentPastDueBanner = shouldShowPaymentPastDueBanner(user)

  // memoize this condition as it is regenerated on every render in other case
  const showPinterestUpgradeBanner = useMemo(() => {
    return (
      user.currentOrganization?.channels &&
      user.currentOrganization?.channels?.filter(
        (channel) =>
          channel.service === 'pinterest' &&
          channel.shouldShowPinterestUpgradeBanner,
      ).length > 0
    )
  }, [user.currentOrganization?.channels])

  const showLinkedInUpgradeBanner =
    user.currentOrganization?.channels &&
    user.currentOrganization?.channels?.filter(
      (channel) =>
        channel.service === 'linkedin' &&
        channel.shouldShowLinkedInUpgradeBanner,
    ).length > 0

  const showTiktokUpgradeBanner =
    user.currentOrganization?.channels &&
    user.currentOrganization?.channels?.filter(
      (channel) =>
        channel.service === 'tiktok' && channel.shouldShowTiktokUpgradeBanner,
    ).length > 0

  const showExpiredCardBanner =
    shouldShowExpiredCardBanner(user) && !showPaymentPastDueBanner

  const { isEnabled: hideTwitter } = useSplitEnabled(
    'hide-twitter-connection-option',
  )

  const showMissingBillingAddressBanner =
    shouldShowMissingBillingAddressBanner(user) && !showExpiredCardBanner

  let showTrialBanner = false
  if (
    isOneBufferOrganization(user) &&
    hasEditPermission(user) &&
    !hasPaymentDetails(user)
  ) {
    showTrialBanner = isOnActiveTrial(user)
  }

  const showFacebookGroupsNotificationsBanner =
    shouldShowFacebookGroupsNotificationsBanner(user)

  const showXMigrationBanner = shouldShowXMigrationBanner(user)

  const shouldShowTeamMemberOrganizationDowngradedBanner =
    user.currentOrganization
      ?.shouldShowTeamMemberOrganizationDowngradedBanner || false

  /* we need to memoize this value as it is an array and it will be regenerated on every render */
  const bannerConfig = useMemo(() => {
    return [
      {
        condition: showXMigrationBanner,
        component: XMigrationBanner,
        slug: 'x-migration-banner',
      },
      {
        condition: showPaymentPastDueBanner,
        component: PaymentPastDueBanner,
        slug: 'payment-past-due',
      },
      {
        condition: user.shouldShowEmailVerificationLinkRequest,
        component: EmailVerificationLinkRequestBanner,
        slug: 'email-verification-link-request',
      },
      {
        condition: showPinterestUpgradeBanner,
        component: ChannelConnectionUpgradeBanner,
        slug: 'pinterest-upgrade',
        extraProps: { channel: 'Pinterest' },
      },
      {
        condition: showLinkedInUpgradeBanner,
        component: ChannelConnectionUpgradeBanner,
        slug: 'linkedin-upgrade',
        extraProps: { channel: 'Linkedin' },
      },
      {
        condition: showTiktokUpgradeBanner,
        component: ChannelConnectionUpgradeBanner,
        slug: 'tiktok-upgrade',
        extraProps: { channel: 'TikTok' },
      },
      {
        condition: hideTwitter,
        component: TwitterIsDownBanner,
        slug: 'twitter-is-down',
      },
      {
        condition: showMissingBillingAddressBanner,
        component: MissingBillingAddress,
        slug: 'missing-billing-address',
      },
      {
        condition: showExpiredCardBanner,
        component: ExpiredCardBanner,
        slug: 'expired-card',
      },
      {
        condition: showTrialBanner,
        component: TrialBanner,
        slug: 'trial-upgrade',
      },
      {
        condition: showFacebookGroupsNotificationsBanner,
        component: FacebookGroupsNotificationsBanner,
        slug: 'facebook-group-notifications',
      },
      {
        condition: shouldShowTeamMemberOrganizationDowngradedBanner,
        component: TeamMemberOrgDowngradedBanner,
        slug: 'team-member-organization-downgraded',
      },
      {
        condition: showTFABanner,
        component: TFABanner,
        slug: 'twoFactorEncouragement',
      },
    ]
  }, [
    showPaymentPastDueBanner,
    user.shouldShowEmailVerificationLinkRequest,
    showPinterestUpgradeBanner,
    showLinkedInUpgradeBanner,
    showTiktokUpgradeBanner,
    hideTwitter,
    showMissingBillingAddressBanner,
    showExpiredCardBanner,
    showTrialBanner,
    showFacebookGroupsNotificationsBanner,
    shouldShowTeamMemberOrganizationDowngradedBanner,
    showXMigrationBanner,
    showTFABanner,
  ])

  /* we need to memoize this value as it is an array and it will be regenerated on every render */
  const bannerViewed = useMemo(() => {
    return bannerConfig
      .filter((banner) => banner.condition)
      .map((banner) => banner.slug)
  }, [bannerConfig])

  useEffect(() => {
    trackBannerViewed({
      payload: {
        bannerViewed,
      },
      user,
    })
  }, [bannerViewed])

  return (
    <>
      {bannerConfig.map((banner) =>
        banner.condition ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <banner.component key={banner.slug} {...(banner.extraProps || {})} />
        ) : null,
      )}
    </>
  )
}

/* Thi savoids this component to re-render on every parent re-render */
export default React.memo(BannerContainer)
