import { differenceInDays } from 'date-fns/differenceInDays'
import type { Account } from '../../../common/types'
import { BannerTypes } from '../../../common/hooks/useDismissBanner'

export function shouldShowTFABanner({
  user,
  createdAt,
  hasTFAEnabled,
}: {
  user: Account
  createdAt: string
  hasTFAEnabled: boolean
}): boolean {
  const isAccountOlderThan2Days = createdAt
    ? differenceInDays(new Date(), new Date(createdAt)) > 2
    : false

  const hasNotDismissedBanner = !user?.dismissedBanners?.includes(
    BannerTypes.tfaBanner,
  )

  return isAccountOlderThan2Days && !hasTFAEnabled && hasNotDismissedBanner
}
