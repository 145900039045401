import React from 'react'
import { Progress } from '@buffer-mono/popcorn'

import styles from './ChannelsCounterBar.module.css'

function ChannelsCounterBar({
  connected,
  limit,
}: {
  connected: number
  limit: number
}): JSX.Element {
  const isOverflow = connected > limit
  const displayAsBar = limit > 5

  if (displayAsBar) {
    const filledPercentage = isOverflow ? 100 : (connected / limit) * 100
    return (
      // We already show the number of channels elsewhere, so we don't
      // need this to be visible to screen readers
      <Progress
        value={filledPercentage}
        max={100}
        size="small"
        color="success"
        className={styles.barContainer}
        aria-hidden="true"
        data-testid="channels-counter-bar"
      />
    )
  }

  return (
    <div
      className={styles.pillContainer}
      aria-hidden="true"
      data-testid="channels-counter-pills"
    >
      {Array.from({ length: limit }).map((_, index) => {
        const isActive = index < connected || isOverflow
        return (
          <div
            key={index}
            data-testid="channel-pill"
            data-status={isActive ? 'active' : 'inactive'}
            className={isActive ? styles.greenPill : styles.greyPill}
          />
        )
      })}
    </div>
  )
}
export default ChannelsCounterBar
