import React from 'react'

import { Banner } from '@buffer-mono/popcorn'
import { getPublishUrl } from '../../../../common/utils/urls'
import { useCrossAppNavigate } from '../../../../common/hooks/useCrossAppNavigate'

type Props = {
  channel: string
}

const ChannelConnectionUpgradeBanner = ({
  channel = 'Linkedin',
}: Props): JSX.Element => {
  const navigateCrossApp = useCrossAppNavigate()
  return (
    <Banner variant="critical">
      <Banner.Text>
        <strong>IMPORTANT UPDATE:</strong> Our {channel} integration has
        enhanced for better performance. Refresh your connection today to keep
        your posts going out smoothly.
      </Banner.Text>
      <Banner.Action
        onClick={(): void => {
          navigateCrossApp(getPublishUrl('settings/channels'))
        }}
      >
        Visit Channels Page
      </Banner.Action>
    </Banner>
  )
}

export default ChannelConnectionUpgradeBanner
