/**
 * Date + time picker combo!
 *
 * DateTimeSlotPicker is timezone-aware, and works with 12/24h formats. If the
 * component is passed a different timezone through props after it's been
 * mounted, it'll update the date and time to that new timezone and go on with
 * its business. The component also ensures the selected date and time are in
 * the future, no matter the timezone.
 *
 * This component is uncontrolled (except for the timezone prop), and uses the
 * onSubmit callback in props to let its parent know of any scheduling action.
 */
import { Temporal } from '@js-temporal/polyfill'
import { InputDate } from '@buffer-mono/legacy-bufferapp-components'
import { Notice, Text } from '@bufferapp/ui'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import dayjs, { type Dayjs } from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { isPast } from '~publish/helpers/temporal'
import { CURRENT_TIME_ZONE } from '~publish/helpers/dateFormatters'

import Select from './shared/Select'

import {
  Button as PopcornButton,
  Text as PopcornText,
} from '@buffer-mono/popcorn'
import type { MetaData } from './types'
import SlotPicker from './SlotPicker'
import TimePicker from './TimePicker'
import { gray, grayDark, grayDarker } from '@bufferapp/ui/style/colors'

dayjs.extend(utc)
dayjs.extend(timezonePlugin)

const DayPickerStyles = css`
  .DayPicker-wrapper > div:first-child {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    gap: var(--space-md) !important;
    width: inherit !important;
    top: 4px !important;

    div {
      float: none !important;
    }

    button {
      display: flex !important;
      align-items: center !important;
      height: 100% !important;
      padding: 4px !important;
      margin: -4px !important;
      border-radius: var(--border-radius-sm) !important;
      box-shadow: none !important;

      span {
        vertical-align: unset !important;
        display: flex !important;
      }

      &:disabled {
        cursor: not-allowed !important;
      }

      &:focus:not(:focus-visible) {
        outline: none !important;
      }

      &:focus-visible {
        outline: 2px solid var(--focus-color) !important;
      }
    }
  }

  .DayPicker-Month > div:first-child {
    display: flex !important;
    align-items: center !important;
    height: inherit !important;
    margin-bottom: var(--space-md) !important;

    span {
      font-size: var(--font-size-body-lg) !important;
    }
  }

  .DayPicker-Day {
    font-weight: var(--font-weight-medium) !important;
    color: ${grayDarker} !important;

    &:not(.DayPicker-Day--selected):not(.DayPicker-Day--disabled):hover {
      background-color: var(--color-bg-subtle) !important;
    }
  }

  .DayPicker-Day--today {
    color: var(--color-text-brand) !important;
  }

  .DayPicker-Day--selected {
    color: var(--color-text-inverted) !important;
    background-color: var(--color-bg-brand) !important;
  }

  .DayPicker-Day--disabled {
    color: ${gray} !important;
    cursor: not-allowed !important;
  }

  .DayPicker-Day--outside {
    color: ${gray} !important;

    &:not(.DayPicker-Day--disabled):hover {
      color: ${grayDarker} !important;
      background-color: var(--color-bg-subtle) !important;
      cursor: pointer !important;
    }
  }
`

const DateTimePicker = styled.div`
  padding: 11px 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--space-md);
  color: ${grayDark};

  ${DayPickerStyles};
`

const PickerSwitchButton = styled(PopcornButton)`
  && {
    flex: 2;
    color: ${grayDarker};
    justify-content: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--space-xs);
`

const SubmitButton = styled(PopcornButton)`
  && {
    flex: 1;
    justify-content: center;
  }
`

const SlotPickerSelect = styled(Select)`
  margin-top: 16px;
`

const NoticeStyled = styled(Notice)`
  margin-top: 10px;
  text-align: left;
  padding: 8px;
  width: calc(100% - 16px);
`

const TextStyled = styled(Text)`
  margin: 0 0 0 -5px;
`

interface Slot {
  isSlotFree: boolean
  timestamp: number
}

interface DateTimeSlotPickerProps {
  timezone?: string
  shouldUse24hTime: boolean
  onSubmit: (timestamp: number) => void
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  metaData?: MetaData
  onChange?: (dateTime: Dayjs, isPinnedToSlot: boolean) => void
  isDateOnly?: boolean
  isDropdownItem?: boolean
  initialDateTime?: Dayjs
  isSlotPickingAvailable?: boolean
  availableSchedulesSlotsForDay?: Slot[] | boolean
  isPinnedToSlot?: boolean | null
  submitButtonCopy?: string
  weekStartsMonday: boolean
  doSelectedProfilesHaveSlots?: boolean
  showDraftScheduleNotice?: boolean
}

const DateTimeSlotPicker: React.FC<DateTimeSlotPickerProps> = ({
  timezone,
  shouldUse24hTime,
  onSubmit,
  onClick,
  metaData,
  onChange = () => {},
  isDateOnly = false,
  isDropdownItem = false,
  initialDateTime,
  isSlotPickingAvailable = false,
  availableSchedulesSlotsForDay,
  isPinnedToSlot = null,
  submitButtonCopy = 'Schedule',
  weekStartsMonday,
  doSelectedProfilesHaveSlots = false,
  showDraftScheduleNotice = false,
}) => {
  const isTimezoneSet = !!timezone
  const todayDate = new Date().setSeconds(0) // Seconds must be 0 for precise scheduling
  // Determine initial date and time for the picker
  const todayDayjs = isTimezoneSet
    ? dayjs.tz(todayDate, timezone)
    : dayjs(todayDate)
  const preselectedDateTime =
    initialDateTime || todayDayjs.clone().add(3, 'hours')
  const draftScheduleNotice =
    "This draft won't be published until you schedule it as a finished post"

  const [selectedDateTime, setSelectedDateTime] = useState(preselectedDateTime)
  const [emptyByDefault, setEmptyByDefault] = useState(false)
  const [displaySlotPicker, setDisplaySlotPicker] = useState(false)

  // When clicking on buttons inside the date picker, the event is propagated
  // up to the Composer App level which causes the composer to collapse and
  // has other unexpected consequences.
  // To prevent this, we stop click event bubbling at the DateTimeSlotPicker level.
  // TODO: Use Popover component to capture clicks and prevent them from bubbling up
  const captureOnClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation()
    // Still run the passed onClick callback if defined
    onClick?.(event)
  }

  const shouldPinPostWithNewDate = (selectedDateTime: Dayjs): boolean => {
    if (!isSlotPickingAvailable || !availableSchedulesSlotsForDay) return false

    const selectedTimestamp = selectedDateTime.unix()
    const isAnySlotSelected = calculatePinnedToSlot(
      availableSchedulesSlotsForDay,
      selectedTimestamp,
    )

    return isAnySlotSelected && (isPinnedToSlot ?? false)
  }

  const updateDate = (date: Date): void => {
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    let newSelectedDateTime

    if (isDateOnly) {
      newSelectedDateTime = selectedDateTime
        .year(year)
        .month(month)
        .date(day)
        .startOf('day')
    } else {
      newSelectedDateTime = selectedDateTime.year(year).month(month).date(day)
    }

    const pinnedToSlot = shouldPinPostWithNewDate(newSelectedDateTime)
    setSelectedDateTime(newSelectedDateTime)
    onChange(newSelectedDateTime, pinnedToSlot)
  }

  const updateTime = (time: Dayjs) => {
    const hours = time.hour()
    const minutes = time.minute()
    const newSelectedDateTime = selectedDateTime.hour(hours).minute(minutes)

    const pinnedToSlot = shouldPinPostWithNewDate(newSelectedDateTime)
    setSelectedDateTime(newSelectedDateTime)

    onChange(newSelectedDateTime, pinnedToSlot)
  }

  const updateTimeFromTimestamp = (timestamp: number) => {
    let newSelectedDateTime = dayjs.unix(timestamp)
    if (timezone) newSelectedDateTime = newSelectedDateTime.tz(timezone)
    const pinnedToSlot = shouldPinPostWithNewDate(newSelectedDateTime)

    setSelectedDateTime(newSelectedDateTime)

    onChange(newSelectedDateTime, pinnedToSlot)
  }

  // Set to UTC, then negate the original offset
  const stripOffsetFromMoment = (m: Dayjs): Dayjs =>
    m.clone().utc().add(m.utcOffset(), 'm')

  const onSlotPickerChange = (timestamp: number) => {
    updateTimeFromTimestamp(timestamp)
    setEmptyByDefault(false)
  }

  const onSwitchToSlotPickerClick = () => {
    // Update UI state
    setDisplaySlotPicker(true)

    // If a slot is available for the current date time, update store to
    // transition from custom time to pinned update mode
    if (!isSlotPickingAvailable || !availableSchedulesSlotsForDay) return

    const selectedTimestamp = selectedDateTime.unix()
    const pinnedToSlot = calculatePinnedToSlot(
      availableSchedulesSlotsForDay,
      selectedTimestamp,
    )
    onChange(selectedDateTime, pinnedToSlot)

    setEmptyByDefault(true)
  }

  const onSwitchToTimePickerClick = () => {
    setDisplaySlotPicker(false)
    onChange(selectedDateTime, false)
  }

  const onClickSubmit = () => {
    if (!initialDateTime) {
      onChange(selectedDateTime, false)
    }
    onSubmit(selectedDateTime.unix())
  }

  const selectedDays = {
    selected: (day: Dayjs) => {
      const selectedDateTimeNoOffset = stripOffsetFromMoment(selectedDateTime)
      const dayNoOffset = stripOffsetFromMoment(dayjs(day))
      return selectedDateTimeNoOffset.isSame(dayNoOffset, 'day')
    },
  }

  const zonedDateTime = Temporal.ZonedDateTime.from({
    timeZone: timezone ?? CURRENT_TIME_ZONE,
    year: selectedDateTime.year(),
    month: selectedDateTime.month() + 1, // Dayjs is 0-11, Temporal is 1-12
    day: selectedDateTime.date(),
    hour: selectedDateTime.hour(),
    minute: selectedDateTime.minute(),
  })

  const shouldDisableSetButton =
    (!isDateOnly && isPast(zonedDateTime)) ||
    (isDateOnly && selectedDateTime.unix() < dayjs().startOf('day').unix())

  const canSwitchToSlots =
    !isDateOnly && isSlotPickingAvailable && doSelectedProfilesHaveSlots

  return (
    <DateTimePicker onClick={captureOnClick} data-testid="date-time-picker">
      <InputDate
        initialMonth={new Date()}
        onDayClick={updateDate}
        // @ts-expect-error
        selectedDays={selectedDays.selected}
        firstDayOfWeek={weekStartsMonday ? 1 : 0}
      />

      {!isDateOnly && !displaySlotPicker && (
        <TimePicker
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          shouldUse24hTime={shouldUse24hTime}
          time={selectedDateTime}
          timezone={timezone}
          onChange={updateTime}
        />
      )}

      {!isDateOnly &&
        displaySlotPicker &&
        isSlotPickingAvailable &&
        (Array.isArray(availableSchedulesSlotsForDay) ? (
          <SlotPicker
            metaData={metaData}
            shouldUse24hTime={shouldUse24hTime}
            timezone={timezone}
            slots={availableSchedulesSlotsForDay}
            slot={selectedDateTime}
            onChange={onSlotPickerChange}
            emptyByDefault={emptyByDefault}
          />
        ) : (
          <SlotPickerSelect disabled value="">
            <option value="">Loading slots…</option>
          </SlotPickerSelect>
        ))}

      <ButtonContainer>
        {canSwitchToSlots && (
          <PickerSwitchButton
            variant="secondary"
            size="large"
            onClick={
              displaySlotPicker
                ? onSwitchToTimePickerClick
                : onSwitchToSlotPickerClick
            }
          >
            {displaySlotPicker
              ? 'Switch to custom time'
              : 'Switch to schedule slots'}
          </PickerSwitchButton>
        )}

        {!isDropdownItem && (
          <SubmitButton
            variant="primary"
            size="large"
            onClick={onClickSubmit}
            disabled={shouldDisableSetButton}
          >
            {submitButtonCopy}
          </SubmitButton>
        )}
      </ButtonContainer>
      {/* Below is needed for the dropdown to work after we implemented */}
      {/* scrollbar in the composer. When we decide in the future that */}
      {/* Composer should no longer be in the modal, we can remove */}
      {/* isDropdownItem prop and below conditional */}
      {isDropdownItem && (
        <DropdownMenu.Item>
          <SubmitButton
            variant="primary"
            size="small"
            onClick={onClickSubmit}
            disabled={shouldDisableSetButton}
          >
            {submitButtonCopy}
          </SubmitButton>
        </DropdownMenu.Item>
      )}

      {isTimezoneSet && (
        <PopcornText size="sm" align="center">
          {timezone}
        </PopcornText>
      )}

      {showDraftScheduleNotice && (
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        <NoticeStyled type="warning" disableAnimation>
          <TextStyled type="p">{draftScheduleNotice}</TextStyled>
        </NoticeStyled>
      )}
    </DateTimePicker>
  )
}

export default DateTimeSlotPicker
function calculatePinnedToSlot(
  availableSchedulesSlotsForDay: boolean | Slot[],
  selectedTimestamp: number,
) {
  return Array.isArray(availableSchedulesSlotsForDay)
    ? availableSchedulesSlotsForDay.some(
        (slot) => slot.timestamp === selectedTimestamp,
      )
    : availableSchedulesSlotsForDay
}
