import React from 'react'
import { Banner } from '@buffer-mono/popcorn'

const TwitterIsDownBanner = (): JSX.Element => {
  return (
    <Banner variant="critical">
      <Banner.Text>
        Due to ongoing changes at Twitter, some of Buffer&apos;s Twitter
        features (including scheduled posts) may not work.
      </Banner.Text>
      <Banner.Action
        onClick={(): void => {
          window.location.href =
            'https://buffer.com/resources/twitter-api-changes/'
        }}
      >
        Learn More
      </Banner.Action>
    </Banner>
  )
}

export default TwitterIsDownBanner
