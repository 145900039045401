import React from 'react'
import SettingsPageLayout from './components/SettingsPageLayout'
import {
  Heading,
  Flex,
  Sidebar,
  Separator,
  EmptyState,
  CriticalIcon,
} from '@buffer-mono/popcorn'
import style from './Settings.module.css'
import { graphql, readFragment } from '~publish/graphql'
import { useQuery } from '@apollo/client'

import PlanContent, {
  PlanContent_Billing,
  LegacyPlanContent_Billing,
  OrganizationDataForBilling_AccountOrganization,
} from './BillingSettings/PlanContent'
import PaymentDetails, {
  PaymentDetails_Billing,
} from './BillingSettings/PaymentDetails'
import BillingInformation, {
  AddressAndTax_Billing,
} from './BillingSettings/BillingInformation'
import Invoices from './BillingSettings/Invoices'

export const BillingFragment_AccountOrganization = graphql(
  /* GraphQL */ `
    fragment BillingFragment_AccountOrganization on AccountOrganization {
      billing {
        ... on OBBilling {
          ...PaymentDetails_Billing
          ...AddressAndTax_Billing
          ...PlanContent_Billing
        }
        ... on MPBilling {
          ...PaymentDetails_Billing
          ...AddressAndTax_Billing
          ...LegacyPlanContent_Billing
        }
      }
    }
  `,
  [
    PaymentDetails_Billing,
    AddressAndTax_Billing,
    LegacyPlanContent_Billing,
    PlanContent_Billing,
  ],
)

const AccountForBillingQuery = graphql(
  /* GraphQL */ `
    query Account {
      account {
        id
        currentOrganization {
          ...BillingFragment_AccountOrganization
          ...OrganizationDataForBilling_AccountOrganization
        }
      }
    }
  `,
  [
    BillingFragment_AccountOrganization,
    OrganizationDataForBilling_AccountOrganization,
  ],
)

export const Billing = ({
  isMobile,
  handleToggleSidebar,
}: {
  isMobile: boolean
  handleToggleSidebar: () => void
}): JSX.Element => {
  const { data, loading, error } = useQuery(AccountForBillingQuery)

  const billingData = data?.account?.currentOrganization
    ? readFragment(
        BillingFragment_AccountOrganization,
        data.account.currentOrganization,
      ).billing
    : null

  const organizationData = data?.account?.currentOrganization ?? null

  if (error) {
    return (
      <SettingsPageLayout>
        <Flex gap="xl" direction="column" className={style.fullWidth}>
          <Heading as="h1" size="large">
            Billing
          </Heading>
          <EmptyState variant="critical">
            <EmptyState.Icon>
              <CriticalIcon />
            </EmptyState.Icon>
            <EmptyState.Heading>
              Failed to load billing information
            </EmptyState.Heading>
            <EmptyState.Description>
              Something went wrong. Please try again later.
            </EmptyState.Description>
          </EmptyState>
        </Flex>
      </SettingsPageLayout>
    )
  }

  return (
    <SettingsPageLayout>
      <Flex direction="column" gap="xl" className={style.fullWidth}>
        {/* Heading */}
        <Flex
          gap="sm"
          direction={isMobile ? 'column' : 'row'}
          align={isMobile ? 'start' : 'center'}
          justify="between"
          className={style.fullWidth}
        >
          <Heading as="h1" size="large">
            <Flex align="center">
              {isMobile && <Sidebar.Trigger onClick={handleToggleSidebar} />}
              Billing
            </Flex>
          </Heading>
        </Flex>

        <Flex direction="column" gap="md" className={style.fullWidth}>
          {/* Plan Content */}
          <PlanContent
            loading={loading}
            billingData={billingData}
            organizationData={organizationData}
          />

          <Separator className={style.fullWidth} />

          {/* Payment Method Details */}
          <PaymentDetails loading={loading} data={billingData} />

          <Separator className={style.fullWidth} />

          {/* Billing information */}
          <BillingInformation loading={loading} data={billingData} />

          <Separator className={style.fullWidth} />

          {/* Invoices */}
          <Invoices />
        </Flex>
      </Flex>
    </SettingsPageLayout>
  )
}

export default Billing
