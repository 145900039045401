import React from 'react'

import useDismissBanner, {
  BannerTypes,
} from '../../../../common/hooks/useDismissBanner'
import { Banner } from '@buffer-mono/popcorn'

export const FacebookGroupsNotificationsBanner = (): JSX.Element | null => {
  const { onDismissBanner, isActive } = useDismissBanner({
    banner: BannerTypes.facebookGroupNotifications,
  })

  if (!isActive) {
    return null
  }

  return (
    <Banner
      variant="info"
      onDismiss={onDismissBanner}
      data-testid="facebook-group-notifications"
    >
      <Banner.Text>
        <strong>Facebook Groups Update:</strong> As of April 22, 2024, Meta will
        no longer support direct publishing to Facebook Groups. From this date,
        all posts must be published through mobile-based notifications.
      </Banner.Text>
      <Banner.Action
        onClick={(): void => {
          window.location.href =
            'https://support.buffer.com/article/570-connecting-facebook-groups-and-troubleshooting-connections'
        }}
      >
        Learn More
      </Banner.Action>
    </Banner>
  )
}
