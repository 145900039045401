import React, { useState } from 'react'

import {
  Button,
  ChevronDownIcon,
  Combobox,
  EmptyState,
  GlobeIcon,
  SearchIcon,
  useControllableState,
  VisuallyHidden,
} from '@buffer-mono/popcorn'

import { CURRENT_TIME_ZONE } from '~publish/helpers/dateFormatters'

import { TimezoneDisplay } from '../TimezoneDisplay'
import {
  useCompatibleTimezone,
  useTimezoneMap,
  useTimezoneSuggestions,
} from './hooks'
import { filterTimezones } from './timezone-filter'
import { TimeZoneSelectItem } from './TimeZoneSelectItem'

import styles from './TimeZoneSelect.module.css'

export type TimeZoneSelectProps = {
  value?: string
  onChange?: (value: string) => void
  defaultValue?: string
  disabled?: boolean
  style?: React.CSSProperties
  className?: string
  size?: React.ComponentProps<typeof Button>['size']
  variant?: React.ComponentProps<typeof Button>['variant']
}

export function TimeZoneSelect({
  disabled,
  onChange,
  value: propValue,
  defaultValue,
  size,
  variant,
  ...props
}: TimeZoneSelectProps): React.ReactElement {
  const [value, setValue] = useControllableState({
    prop: propValue ?? CURRENT_TIME_ZONE,
    defaultProp: defaultValue,
    onChange,
  })
  const [searchInputValue, setSearchInputValue] = useState('')
  const timezones = useTimezoneMap()
  const compatibleTimezone = useCompatibleTimezone(value)
  const [timeZoneSuggestions, filteredTimezones] =
    useTimezoneSuggestions(searchInputValue)

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setSearchInputValue(event.target.value)
  }

  const handleOpenChange = (open: boolean): void => {
    if (!open) {
      setSearchInputValue('')
    }
  }

  if (!compatibleTimezone) {
    return <TimezoneDisplay timezone={value} />
  }

  return (
    <Combobox
      onChange={setValue}
      multiple={false}
      value={compatibleTimezone.name}
      filter={filterTimezones.bind(null, timezones)}
      onOpenChange={handleOpenChange}
      {...props}
    >
      <Combobox.Trigger>
        <Button
          disabled={disabled}
          size={size}
          variant={variant}
          data-testid="timezone-form-button"
        >
          <GlobeIcon />
          <VisuallyHidden>Timezone</VisuallyHidden>
          {compatibleTimezone.name.split('/')[1].replace('_', ' ') ??
            compatibleTimezone.mainCities[0]}
          <ChevronDownIcon />
        </Button>
      </Combobox.Trigger>
      <Combobox.Content className={styles.content}>
        <Combobox.Input
          placeholder="Search cities or timezones"
          prefix={<SearchIcon />}
          onChange={handleInputChange}
          value={searchInputValue}
        />
        <Combobox.List>
          <Combobox.Empty>
            <EmptyState size="small">
              <EmptyState.Icon>
                <SearchIcon />
              </EmptyState.Icon>
              <EmptyState.Description>
                No matching time zones found
              </EmptyState.Description>
            </EmptyState>
          </Combobox.Empty>
          <Combobox.Group heading="Suggestions">
            {timeZoneSuggestions.length > 0 &&
              timeZoneSuggestions.map((timezone) => (
                <TimeZoneSelectItem timezone={timezone} key={timezone.name} />
              ))}
          </Combobox.Group>
          <Combobox.Separator />
          <Combobox.Group
            heading={searchInputValue ? undefined : 'All timezones'}
          >
            {filteredTimezones.map((timezone) => (
              <TimeZoneSelectItem timezone={timezone} key={timezone.name} />
            ))}
          </Combobox.Group>
        </Combobox.List>
      </Combobox.Content>
    </Combobox>
  )
}
