import React, { useCallback, useEffect, useState } from 'react'
import { Banner } from '@buffer-mono/popcorn'
import {
  getPublishUrl,
  isOnChannelSettingsRoute,
} from '../../../../common/utils/urls'
import { useCrossAppNavigate } from '../../../../common/hooks/useCrossAppNavigate'
import { useProductHistory } from '../../utils/useProductHistory'

const XMigrationBanner = (): JSX.Element => {
  const navigateCrossApp = useCrossAppNavigate()
  const { history } = useProductHistory()
  const [showAction, setShowAction] = useState(!isOnChannelSettingsRoute())

  const handleShowAction = useCallback(() => {
    const isOnChannelSettingsPage = isOnChannelSettingsRoute()

    setShowAction(!isOnChannelSettingsPage)
  }, [])

  useEffect(() => {
    if (history) {
      const unlisten = history.listen(handleShowAction)
      return () => unlisten()
    }
  }, [history, handleShowAction])

  return (
    <Banner variant="critical">
      <Banner.Text>
        <strong>ACTION REQUIRED:</strong> Refresh your X (Twitter) connection to
        continue publishing and accessing analytics
      </Banner.Text>
      {showAction && (
        <Banner.Action
          onClick={(): void => {
            navigateCrossApp(getPublishUrl('settings/channels'))
          }}
        >
          Go to Channels
        </Banner.Action>
      )}
    </Banner>
  )
}

export default XMigrationBanner
