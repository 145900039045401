import { graphql } from '~publish/gql'
import { useDismissAllCommentsByChannelId } from './usedismissAllCommentsByChannelId'

export const DismissAllCommentsByChannelIdMutation = graphql(/* GraphQL */ `
  mutation DismissAllCommentsByChannelId(
    $input: DismissAllCommentsByChannelIdInput!
  ) {
    dismissAllCommentsByChannelId(input: $input) {
      __typename
      ... on DismissAllCommentsSuccess {
        status
        affectedCommentCount
      }
      ... on NotFoundError {
        message
      }
      ... on UnauthorizedError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

export type DismissAllCommentsByChannelId = (channelId: string) => Promise<void>
/* Add here more dismissAll actions like DismissAllCommentsByPostId */

export type UseDismissAllCommentByChannelIdResult = {
  dismissAllByChannelId: DismissAllCommentsByChannelId
}

/**
 *
 * Hook to dismiss all comments in an organization, channel or post
 * @returns {UseDismissAllCommentByChannelIdResult} - The dismissAllByChannelId function
 *
 * @example
 * const { dismissAllByChannelId } = useDismissAll()
 *
 * const handleDismissAllComments = useCallback(async (): Promise<void> => {
 *  await dismissAllByChannelId(channelId)
 * }, [dismissAllByChannelId, channelId])
 *
 */
export function useDismissAll(): UseDismissAllCommentByChannelIdResult {
  const dismissAllByChannelId = useDismissAllCommentsByChannelId()
  /* we'll add here more mutations like dismissAllByPostId */

  return { dismissAllByChannelId }
}
