import React, { useMemo } from 'react'
import { CURRENT_TIME_ZONE } from '~publish/helpers/dateFormatters'

type TimeZoneContextType = {
  timeZone: string
}

const TimeZoneContext = React.createContext<TimeZoneContextType>({
  timeZone: CURRENT_TIME_ZONE,
})

export const useTimezone = (): string => {
  const { timeZone } = React.useContext(TimeZoneContext)
  return timeZone
}

export const TimezoneProvider: React.FC<{
  children: React.ReactNode
  timeZone?: string
}> = ({ children, timeZone = CURRENT_TIME_ZONE }) => {
  const value = useMemo(
    () => ({
      timeZone,
    }),
    [timeZone],
  )

  return (
    <TimeZoneContext.Provider value={value}>
      {children}
    </TimeZoneContext.Provider>
  )
}
