import React from 'react'
import { type ApolloError, useQuery } from '@apollo/client'

import { type FragmentOf, graphql } from '~publish/graphql'
import { sanitizeNullableArray } from '~publish/helpers/typeGuards'
import { useAppSelector } from '~publish/legacy/store'
import { useCommentsConfig } from '~publish/pages/Comments/CommentsChannelConfigContext'

export const CommentsChannelNavChannelFragment = graphql(`
  fragment CommentsChannelNavChannelFragment on Channel @_unmask {
    id
    name
    service
    avatar
    serverUrl
    locationData {
      location
    }
    isLocked
    isDisconnected
    type
    apiVersion
  }
`)

export const GetCommentsSidebarInfo = graphql(
  `
    query GetCommentsSidebarInfo {
      # TODO: channels should be a top-level query
      account {
        id
        currentOrganization {
          id
          channels(product: publish) {
            id
            name
            service
            avatar
            ...CommentsChannelNavChannelFragment
          }
        }
      }
    }
  `,
  [CommentsChannelNavChannelFragment],
)

export type CommentsChannelNav = FragmentOf<
  typeof CommentsChannelNavChannelFragment
>

export function useCommentsChannels(): {
  channels: CommentsChannelNav[]
  loading: boolean
  error: ApolloError | undefined
} {
  const {
    data: channelsData,
    loading,
    error,
  } = useQuery(GetCommentsSidebarInfo)

  const { config } = useCommentsConfig()
  const { isSupportedInComments, isSupportedInEngage } = config

  const profilesIdOrder =
    useAppSelector((state) =>
      state.profileSidebar.profiles.map((profile) => profile.id),
    ) ?? []

  const channels = sanitizeNullableArray(
    channelsData?.account?.currentOrganization?.channels,
  ).sort((a, b) => {
    // First priority: Supported channels (Comments)
    const aIsSupported = isSupportedInComments(a)
    const bIsSupported = isSupportedInComments(b)
    if (aIsSupported !== bIsSupported) {
      return aIsSupported ? -1 : 1
    }

    // Second priority: Engagement channels (Facebook, Instagram)
    const aIsEngagement = isSupportedInEngage(a)
    const bIsEngagement = isSupportedInEngage(b)
    if (aIsEngagement !== bIsEngagement) {
      return aIsEngagement ? -1 : 1
    }

    // Third priority: Original profile order
    // TODO: Since we don't have order implemented on the GraphQL API,
    // we sort by the order of the profiles in the sidebar stored in Redux.
    // This should go away once we have order implemented on the API.
    const aIndex = profilesIdOrder.indexOf(a.id)
    const bIndex = profilesIdOrder.indexOf(b.id)
    if (aIndex === -1) return 1
    if (bIndex === -1) return -1
    return aIndex - bIndex
  })

  return React.useMemo(
    () => ({
      channels,
      loading,
      error,
    }),
    [channels, loading, error],
  )
}
