import { useSplitEnabled } from '@buffer-mono/features'
import React from 'react'

import PlayIcon from '@bufferapp/ui/Icon/Icons/Play'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'

import { MediaTypes } from '~publish/legacy/constants'
import type { Draft } from '../../entities/Draft'
import { Document } from '../../entities/Document'
import {
  isMediaImage,
  isMediaVideo,
  type Media,
} from '../../entities/factories'
import { escapeParens } from '../../utils/StringUtils'
import Button from '../shared/Button'
import CloseButton from '../shared/CloseButton'
import styles from '../css/MediaAttachmentThumbnail.module.css'
import {
  getTags,
  renderCanvaEditOption,
  renderEditOption,
  renderAltOption,
  renderTagOption,
} from './helpers'
import {
  useMediaAttachmentFeedback,
  useMediaAttachmentThumbnailHandlers,
} from './hooks'
import {
  ThumbnailOverlay,
  ValidationWarningIcon,
  ValidationWarningWrapper,
  VideoTag,
} from './styles'
import { Tooltip, type TooltipProps } from '@buffer-mono/popcorn'
import { getQueueResizedImageUrl } from '~publish/legacy/utils/bufferImages'
import type { Uploader } from '@buffer-mono/uploader'
import { Info } from '@bufferapp/ui/Icon/index'

export interface MediaAttachmentThumbnailProps {
  media: Media
  className: string
  showImageDescription: boolean
  draft: Draft
  canAddUserTag?: boolean
  uploader: Uploader
}

const MAX_MP_FOR_UPLOADCARE = 75_000_000

function isImageTooBigForUploadCare(
  width: number | null,
  height: number | null,
): boolean {
  // if we don't have access to size information, we can't determine if the image is too big
  if (width === null || height === null) {
    return false
  }

  return width * height > MAX_MP_FOR_UPLOADCARE
}

const TooltipWrapper = ({ ...props }: TooltipProps): JSX.Element => {
  if (!props.content) {
    return <>{props.children}</>
  }
  return <Tooltip {...props} />
}

const MediaAttachmentThumbnail = ({
  media,
  className,
  showImageDescription,
  draft,
  canAddUserTag = false,
  uploader,
}: MediaAttachmentThumbnailProps): JSX.Element => {
  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')
  const isRegularImage = media.mediaType === MediaTypes.IMAGE
  const isImageTooBig = isRegularImage
    ? isImageTooBigForUploadCare(media.width, media.height)
    : false
  const {
    onClick,
    onCloseButtonClick,
    onAddTagClick,
    onCanvaEditButtonClick,
    onEditButtonClick,
  } = useMediaAttachmentThumbnailHandlers({
    media,
    draft,
    showImageDescription,
    uploader,
  })

  const mediaFeedback = useMediaAttachmentFeedback(media.url, draft.id)
  const shouldShowInstagramRatioNotice =
    draft.id === 'instagram' &&
    draft.updateType !== 'story' &&
    media.mediaType === MediaTypes.IMAGE &&
    media.width &&
    media.height
      ? media.width / media.height !== 4 / 5
      : false

  const feedbackMessages = isRemindersEnabled
    ? mediaFeedback
        .map((validation) => validation?.messageOptions?.specific)
        .join('\n')
    : []

  const thumbnailClassName = [styles.thumbnail, className].join(' ')
  const isVideo = isMediaVideo(media)
  const thumbnail = Document.isDocument(media)
    ? media.thumbnailUrl
    : isVideo
    ? media?.thumbnail
    : media.url
  const tags = getTags(draft, media.url)
  const userTagCount = tags ? tags.length : null

  const tooltipCopy =
    isRegularImage && showImageDescription
      ? 'Click to expand & add description'
      : 'Click to expand'
  const ariaLabel =
    isRegularImage && showImageDescription
      ? 'Select to expand image and add image description'
      : `Select to expand ${isVideo ? 'video' : 'image'}`

  const buttonWrapperClassName = [
    mediaFeedback.length && isRemindersEnabled ? styles.warningBorder : '',
    styles.buttonWrapper,
  ].join(' ')

  const canEditCanvaImage =
    isMediaImage(media) && media?.source?.name === 'canva'

  return (
    <div className={thumbnailClassName}>
      <div className={buttonWrapperClassName}>
        <TooltipWrapper content={tooltipCopy}>
          <Button
            className={styles.imageContainer}
            aria-label={ariaLabel}
            onClick={onClick}
            data-testid="media-attachment-thumbnail"
          >
            {isVideo && <ThumbnailOverlay />}
            <img
              className={styles.thumbnailImage}
              alt=""
              src={getQueueResizedImageUrl(escapeParens(thumbnail))}
              data-testid="media-attachment-thumbnail-image"
              crossOrigin="anonymous"
            />
            {isVideo && (
              <VideoTag aria-label="video attachment">
                <PlayIcon />
              </VideoTag>
            )}
          </Button>
        </TooltipWrapper>
      </div>
      <div className={styles.actionButtonWrapper}>
        {canAddUserTag && renderTagOption(userTagCount, onAddTagClick)}
        {canEditCanvaImage && renderCanvaEditOption(onCanvaEditButtonClick)}
        {showImageDescription && renderAltOption(onClick)}
        {isRegularImage && renderEditOption(onEditButtonClick, isImageTooBig)}
      </div>
      {shouldShowInstagramRatioNotice && mediaFeedback.length === 0 && (
        <Tooltip content="For best results on your Instagram Grid and Feed, we recommend uploading images with a 4:5 aspect ratio.">
          <ValidationWarningWrapper>
            <ValidationWarningIcon>
              <Info color="#2C4BFF" />
            </ValidationWarningIcon>
          </ValidationWarningWrapper>
        </Tooltip>
      )}

      {!!mediaFeedback.length && (
        <TooltipWrapper content={feedbackMessages}>
          <ValidationWarningWrapper>
            <ValidationWarningIcon>
              <WarningIcon />
            </ValidationWarningIcon>
          </ValidationWarningWrapper>
        </TooltipWrapper>
      )}
      <CloseButton
        className={styles.closeButton}
        onClick={onCloseButtonClick}
        label="Remove media"
      />
    </div>
  )
}

export default MediaAttachmentThumbnail
