const getHumanReadableSize = (bytes: number | null): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

  if (bytes === 0 || bytes == null) {
    return 'n/a'
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1024))

  if (i === 0) {
    return `${bytes} ${sizes[i]}`
  }

  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
}

const calculateAspectRatio = (width: number, height: number): string => {
  const greatestCommonDivisor = (a: number, b: number): number => {
    if (b === 0) {
      return a
    } else {
      return greatestCommonDivisor(b, a % b)
    }
  }
  const divisor = greatestCommonDivisor(width, height)
  return (
    width + 'x' + height + 'px, ' + width / divisor + ':' + height / divisor
  )
}

const getHumanReadableTime = (unformattedSeconds: number): string => {
  const minutes = Math.floor(unformattedSeconds / 60)
  const seconds = unformattedSeconds - minutes * 60

  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
    2,
    '0',
  )}`
}

const getFileTypeFromPath = (path: string): string => {
  const fileNameParts = path.split('.')

  return fileNameParts.length > 1 ? fileNameParts[fileNameParts.length - 1] : ''
}

/**
 * Take advantage of the String iterator working with full unicode symbols rather than
 * surrogate pairs to make unicode-aware computations.
 *
 * getUnicodeAwareLength(str) returns the unicode-aware length of a string.
 * makeUnicodeAwareIndexUnaware(str, i) when given a unicode-aware index in a string,
 * returns its unicode-unaware counterpart.
 */
const getUnicodeAwareLength = (str: string): number => [...str].length
const makeUnicodeAwareIndexUnaware = (str: string, i: number): number =>
  Array.from(str).slice(0, i).join('').length

/**
 * Escape parens in a string. Useful for CSS style tags, where external resources
 * linked using `url()` have parens acting as special characters.
 */
const cachedParensRegex = /(\(|\))/g
const escapeParens = (str?: string): string =>
  str?.replace(cachedParensRegex, '\\$1') || ''

const getDomainFromUrl = (url: string): string =>
  url.replace(/(?:(?:https?|ftp):\/\/)?(?:www\.)?/, '').split(/[/?#]/)[0]

const getBaseUrl = () =>
  window.location.hostname === 'publish.local.buffer.com'
    ? 'https://local.buffer.com'
    : 'https://buffer.com'

const removeLinkFromErrorMessageText = (
  text: string,
  linkClass: string,
): string => {
  const rx = new RegExp(`<a class="${linkClass}".*?</a>`)

  return text.replace(rx, '')
}

const ensureUrlProtocol = (url: string): string => {
  return !url.match(/https?:\/\//) ? `https://${url}` : url
}

const isUrlOnBlocklist = (url: string): boolean => {
  // https://github.com/bufferapp/buffer-scraper/blob/master/lib/blocklist.js
  const blockList = [
    /^(\w+\.)?instagram\.com/i,
    /^(\w+\.)?instagr.am/i,
    /^(\w+\.)?facebook\.com/i,
    /^(\w+\.)?fb\.com/i,
    /^(\w+\.)?fb\.me/i,
  ]

  const safeUrl = ensureUrlProtocol(url)
  try {
    const firstUrl = new URL(safeUrl)
    return blockList.some((blockedHost) => blockedHost.test(firstUrl.host))
  } catch (e) {
    // If we can't parse the URL for some reason then assume it's fine
    console.warn(`Error parsing URL ${safeUrl}`, e) // eslint-disable-line no-console
    return false
  }
}

const cleanUrl = (url: string): string => {
  return url.replace(/https:\/\/|http:\/\//gi, '').replace(/\/.*/g, '')
}

const removeUrlProtocol = (url: string): string => {
  return url.replace(/http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g, '')
}

const isValidHttpUrl = (link: string): boolean => {
  let url

  try {
    url = new URL(link)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export {
  getHumanReadableSize,
  getHumanReadableTime,
  getFileTypeFromPath,
  escapeParens,
  getUnicodeAwareLength,
  makeUnicodeAwareIndexUnaware,
  getDomainFromUrl,
  removeLinkFromErrorMessageText,
  getBaseUrl,
  isUrlOnBlocklist,
  ensureUrlProtocol,
  cleanUrl,
  removeUrlProtocol,
  isValidHttpUrl,
  calculateAspectRatio,
}
