import { format } from 'date-fns'
import { tz, type TZDate } from '@date-fns/tz'

export const CURRENT_TIME_ZONE =
  Intl.DateTimeFormat().resolvedOptions().timeZone

/**
 * Formats a time zone string by replacing underscores with spaces.
 * @param {string} timeZone - The time zone string to format.
 * @returns {string} The formatted time zone string.
 */
export const formatTimeZone = (timeZone: string): string => {
  return timeZone.replace(/_/g, ' ')
}

/**
 * Options for formatting date and time.
 */
export type DateTimeFormatterOptions = {
  /** The time zone to use for formatting. */
  timeZone?: string
  /** The time format to use (12 or 24 hour). */
  timeFormat?: '24' | '12'
  /** The date format to use. */
  dateFormat?: string
  /** Whether to include the time zone in the output. */
  includeTimeZone?: boolean
}

/**
 * Default options for date and time formatting.
 */
const defaultOptions = {
  timeZone: CURRENT_TIME_ZONE,
  timeFormat: '24',
  dateFormat: 'MMM d,',
  includeTimeZone: true,
  includeDate: true,
  includeTime: true,
}

/**
 * Returns a UTC string representation of a date.
 *
 * TZDate.toISOString does return +00:00 offest instead of Z prefix
 * that's why we need to go through this conversion
 *
 * @param {TZDate | Date} date - The date to convert to UTC.
 * @returns {string} The UTC string representation of the date.
 */
export const getUTCString = (date: TZDate | Date): string =>
  new Date(date.getTime()).toISOString()

/**
 * Formats a date and time string according to the provided options.
 * @param {string} date - The date string to format.
 * @param {DateTimeFormatterOptions & { includeDate?: boolean, includeTime?: boolean }} options - Formatting options.
 * @returns {string} The formatted date and time string.
 */
export const formatDateTime = (
  date: string,
  options: DateTimeFormatterOptions & {
    includeDate?: boolean
    includeTime?: boolean
  } = {},
): string => {
  const {
    timeZone,
    timeFormat,
    dateFormat,
    includeDate,
    includeTime,
    includeTimeZone,
  } = { ...defaultOptions, ...options }
  const _timeFormat = timeFormat === '24' ? 'HH:mm' : 'h:mm a'
  let dateTimeFormat = `${dateFormat} ${_timeFormat}`
  if (!includeDate) {
    dateTimeFormat = _timeFormat
  }
  if (!includeTime) {
    dateTimeFormat = dateFormat
  }

  const formattedDate = format(date, dateTimeFormat, {
    in: tz(timeZone),
  })

  if (!includeTimeZone) {
    return formattedDate
  }
  return `${formattedDate} (${formatTimeZone(timeZone)})`
}

/**
 * Formats a date string to show only the time.
 * @param {string} date - The date string to format.
 * @param {DateTimeFormatterOptions} options - Formatting options.
 * @returns {string} The formatted time string.
 */
export const formatTime = (
  date: string,
  options: DateTimeFormatterOptions = {},
): string => {
  return formatDateTime(date, {
    includeTimeZone: false,
    ...options,
    includeDate: false,
  })
}

/**
 * Formats a date string to show only the date.
 * @param {string} date - The date string to format.
 * @param {DateTimeFormatterOptions} options - Formatting options.
 * @returns {string} The formatted date string.
 */
export const formatDate = (
  date: string,
  options: DateTimeFormatterOptions = {},
): string => {
  return formatDateTime(date, { ...options, includeTime: false })
}

/**
 * Checks if a date is valid.
 * @param {string | number | Date} date - The date to check.
 * @returns {boolean} True if the date is valid, false otherwise.
 */
export const isValidDateTime = (date: string | number | Date): boolean => {
  if (date instanceof Date) {
    return !isNaN(date.getTime())
  }

  if (typeof date === 'number' || typeof date === 'string') {
    const parsedDate = new Date(date)
    return !isNaN(parsedDate.getTime())
  }

  return false
}

const UNIX_EPOCH = new Date('1970-01-01T00:00:00.000Z')

export const isUnixEpochDate = (date: string | number | Date): boolean => {
  const dateToCheck = date instanceof Date ? date : new Date(date)
  return dateToCheck.getTime() === UNIX_EPOCH.getTime()
}
