import React from 'react'
import { Banner } from '@buffer-mono/popcorn'
import { useUser } from '../../../../common/context/User'
import { getBillingPaymentMethodUrl } from '../../../../common/utils/urls'
import {
  calculateDaysTillUserCreditCardExpires,
  isUserCreditCardExpired,
} from '../../../../common/utils/user'

const EXPIRED_CARD_COPY =
  'Your credit card on file is expired! Please update your billing information to ensure your subscription remains in good standing.'
const EXPIRING_CARD_COPY = (remainingDays: number): string =>
  `Your credit card on file will expire in ${remainingDays} days! Please update your billing information to ensure your subscription remains in good standing. `

const ExpiredCardBanner = (): JSX.Element => {
  const user = useUser()

  const remainingDaysTillExpiry = calculateDaysTillUserCreditCardExpires(user)
  const alertCopy = isUserCreditCardExpired(user)
    ? EXPIRED_CARD_COPY
    : EXPIRING_CARD_COPY(remainingDaysTillExpiry)

  return (
    <Banner variant="critical">
      <Banner.Text>
        <strong>ACTION REQUIRED:</strong> {alertCopy}
      </Banner.Text>
      <Banner.Action
        onClick={(): void => {
          window.location.assign(getBillingPaymentMethodUrl())
        }}
      >
        Update Billing Info
      </Banner.Action>
    </Banner>
  )
}

export default ExpiredCardBanner
