import React from 'react'
import { Banner } from '@buffer-mono/popcorn'
import useEmailVerification from '../../../../common/hooks/useEmailVerification'
import useDismissBanner, {
  BannerTypes,
} from '../../../../common/hooks/useDismissBanner'

const EmailVerificationLinkRequestBanner = (): JSX.Element | null => {
  const { bannerOptions } = useEmailVerification()
  const { onDismissBanner, isActive } = useDismissBanner({
    banner: BannerTypes.emailVerification,
  })

  if (!isActive) {
    return null
  }

  if (bannerOptions?.text) {
    return (
      <Banner variant="info">
        <Banner.Text>{bannerOptions.text}</Banner.Text>
      </Banner>
    )
  }

  return (
    <Banner variant="critical" onDismiss={onDismissBanner}>
      <Banner.Text>
        We&apos;ve updated our security measures. Please verify your email.
      </Banner.Text>
      <Banner.Action
        onClick={bannerOptions?.actionButton?.action}
        variant="critical"
      >
        Verify Email
      </Banner.Action>
    </Banner>
  )
}

export default EmailVerificationLinkRequestBanner
