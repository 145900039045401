import React from 'react'
import { Banner } from '@buffer-mono/popcorn'
import { useUser } from '../../../../common/context/User'

const TeamMemberOrgDowngradedBanner = (): JSX.Element => {
  const user = useUser()
  const ownerEmail = user.currentOrganization?.ownerEmail
  const ownerEmailText = ownerEmail ? `, ${ownerEmail}, ` : ' '

  return (
    <Banner variant="critical" data-testid="payments-past-due-banner">
      <Banner.Text>
        The organization owner {ownerEmailText} has downgraded to a plan that
        does not support team members, which means you no longer have access to
        publishing. Please contact them to upgrade.
      </Banner.Text>
    </Banner>
  )
}

export default TeamMemberOrgDowngradedBanner
