import React, { useState } from 'react'
import {
  Button,
  ChannelAvatar,
  CheckboxCard,
  Dialog,
  Flex,
  Input,
  Label,
  Text,
  Tooltip,
} from '@buffer-mono/popcorn'
import { ConditionalWrapper } from '~publish/legacy/shared-components'
import { useChannels } from '~publish/components/PublishSidebar/useChannels'

import styles from './ChannelGroupDialog.module.css'
import { useAppDispatch } from '~publish/legacy/store'
import { createGroup, updateGroup } from '../thunks'

interface ChannelGroupDialogProps {
  children?: React.ReactNode
  onSaved: () => void
  channelGroup?: {
    id: string
    name: string
    profile_ids: string[]
  }
}

export function ChannelGroupDialog({
  channelGroup,
  onSaved,
  children,
}: ChannelGroupDialogProps): JSX.Element {
  const dispatch = useAppDispatch()
  const { channels } = useChannels()
  const [groupName, setGroupName] = useState('')
  const [open, setOpen] = useState(false)
  const [selectedChannels, setSelectedChannels] = useState<string[]>([])

  React.useEffect(() => {
    if (channelGroup) {
      setGroupName(channelGroup.name)
      setSelectedChannels(channelGroup.profile_ids)
    } else {
      setGroupName('')
      setSelectedChannels([])
    }
  }, [channelGroup])

  const handleSubmit = async (
    evt: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    evt.preventDefault()

    if (channelGroup?.id) {
      await handleUpdateGroup(channelGroup?.id, groupName, selectedChannels)
    } else {
      await handleCreateGroup(groupName, selectedChannels)
    }
    onSaved()
    setOpen(false)
  }

  const handleCreateGroup = async (
    groupName: string,
    selectedChannels: string[],
  ): Promise<void> => {
    await dispatch(createGroup({ groupName, selectedChannels }))
  }

  const handleUpdateGroup = async (
    groupId: string,
    groupName: string,
    selectedChannels: string[],
  ): Promise<void> => {
    await dispatch(updateGroup({ groupId, groupName, selectedChannels }))
  }

  const handleChannelToggle = (channelId: string): void => {
    setSelectedChannels((prev) => {
      const newSelectedChannels = prev.includes(channelId)
        ? prev.filter((id) => id !== channelId)
        : [...prev, channelId]
      return newSelectedChannels
    })
  }

  const isFormValid = groupName.trim() !== '' && selectedChannels.length > 0
  const getTooltipContent = (): string | null => {
    if (groupName.trim() === '') {
      return 'Please enter a group name'
    }
    if (selectedChannels.length === 0) {
      return 'Please select at least one channel'
    }
    return null
  }

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      {children && <Dialog.Trigger>{children}</Dialog.Trigger>}
      <Dialog.Content size="medium" className={styles.dialog}>
        <Dialog.Header className={styles.header}>
          <Dialog.Title>
            {channelGroup ? 'Edit Channel Group' : 'Create New Channel Group'}
          </Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <form onSubmit={handleSubmit}>
            <Label htmlFor="groupName">Group Name</Label>
            <Input
              id="groupName"
              value={groupName}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>): void =>
                setGroupName(evt.target.value)
              }
              required
            />
            <Flex direction="column" className={styles.channels}>
              <Label>Select Channels</Label>
              {!channels?.length ? (
                <Text>No channels found</Text>
              ) : (
                <CheckboxCard.Group
                  aria-label="Select channel"
                  className={styles.group}
                >
                  {channels.map((channel) => (
                    <CheckboxCard
                      key={channel.id}
                      onCheckedChange={(): void =>
                        handleChannelToggle(channel.id)
                      }
                      checked={selectedChannels.includes(channel.id)}
                      className={styles.card}
                    >
                      <Flex gap="sm" align="center">
                        <ChannelAvatar
                          size="small"
                          alt={channel.name}
                          src={channel.avatar}
                          name={channel.name}
                          channel={channel.service}
                          className={styles.avatar}
                        />
                        <Text>{channel.name}</Text>
                      </Flex>
                    </CheckboxCard>
                  ))}
                </CheckboxCard.Group>
              )}
            </Flex>
            <Flex
              gap="xs"
              justify="start"
              direction="row-reverse"
              className={styles.actions}
            >
              <ConditionalWrapper
                condition={!isFormValid}
                wrapper={(children: React.ReactNode): JSX.Element => (
                  <Tooltip content={getTooltipContent()}>{children}</Tooltip>
                )}
              >
                <Button
                  variant="primary"
                  size="large"
                  type="submit"
                  disabled={!isFormValid}
                >
                  Save
                </Button>
              </ConditionalWrapper>
              <Dialog.Close>
                <Button variant="tertiary" size="large">
                  Cancel
                </Button>
              </Dialog.Close>
            </Flex>
          </form>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog>
  )
}
