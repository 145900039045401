import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import clsx from 'clsx'
import mixpanel from 'mixpanel-browser'

import {
  Button,
  ChannelAvatar,
  type ChannelType,
  CheckIcon,
  Dialog,
  Flex,
  Heading,
  Notice,
  Paragraph,
  RadioCard,
  toast,
} from '@buffer-mono/popcorn'
import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'

import { useUser } from '../../../../common/context/User'
import type { Channel } from '../../../../common/types'
import { getChannelFromAccount } from '../../../../common/utils/channels'
import { DEFAULT_POSTING_GOAL, POSTING_GOAL_OPTIONS } from './constants'
import { UPDATE_CHANNEL_POSTING_GOAL_MUTATION } from './mutations'
import { Confetti } from './Confetti'

import styles from './PostingGoalConfiguration.module.css'

function PostingGoalConfiguration({
  channelIds: passedChannelIds,
  dismissModal,
  remindLater = false,
  isSuccessConnection = false,
}: {
  channelIds?: string[]
  dismissModal: () => void
  remindLater?: boolean
  isSuccessConnection?: boolean
}): JSX.Element {
  const account = useUser()
  const [selectedGoal, setSelectedGoal] = useState<string>(
    DEFAULT_POSTING_GOAL.goal.toString(),
  )
  const [updatePostingGoal, { error, loading }] = useMutation(
    UPDATE_CHANNEL_POSTING_GOAL_MUTATION,
    {
      refetchQueries: [
        'GetChannelInfo',
        'GetPostingScheduleInfo',
        'checklists',
      ],
    },
  )

  const channelIds = passedChannelIds ?? [getChannelFromUrl()]

  const channels = channelIds
    ?.filter((x): x is string => Boolean(x))
    .map((channelId) => getChannelFromAccount(account, channelId))
  const channel = channels?.[0]
  const organization = account?.currentOrganization

  useEffect(() => {
    if (organization?.id && channel?.id && isSuccessConnection) {
      // Start Mixpanel session recording when the modal is shown after a successful connection
      try {
        mixpanel.start_session_recording()
      } catch {
        // Mix panel is not initialized, do nothing
      }

      BufferTracker.channelConnectionGoalModalViewed({
        organizationId: organization.id,
        channelId: channel.id,
        channelUsername: channel.name,
        channelService: channel.service as string,
        clientName: Client.PublishWeb,
        ...organization?.commonTrackingProperties,
      })
    }
  }, [organization, channel, isSuccessConnection])

  if (!channel) {
    return <></>
  }

  const handleCreatePostingPlan = async (): Promise<void> => {
    const selectedOption = POSTING_GOAL_OPTIONS.find(
      (option) => option.goal === Number(selectedGoal),
    )
    if (!selectedOption) return
    try {
      // Create a posting goal for each channel in the channelIds array
      const failedChannels: Channel[] = []
      for (const channel of channels || []) {
        const result = await updatePostingGoal({
          variables: {
            input: {
              channelId: channel.id,
              goal: selectedOption.goal,
              overridePostingSchedules: isSuccessConnection,
            },
          },
        })
        if (result.errors) {
          failedChannels.push(channel)
        }
      }

      if (failedChannels.length > 0) {
        if (failedChannels.length === 1) {
          toast.error(
            `Failed to create posting goal for ${
              failedChannels[0]?.name || 'a channel'
            }.`,
          )
        } else {
          toast.error(
            `Failed to create posting goals for ${failedChannels.length} channels.`,
          )
        }
      }
    } catch (error) {
      toast.error('There was an issue creating your posting goal.')
    }
    dismissModal()
    toast.success('Posting goal created successfully.')
  }

  const handleOpenChange = (open: boolean): void => {
    if (!open) {
      handleRemindLater()
    }
  }

  const handleRemindLater = (): void => {
    if (organization?.id && channel?.id && isSuccessConnection) {
      BufferTracker.postingGoalSkipped({
        organizationId: organization.id,
        channelId: channel.id,
        channelUsername: channel.name,
        channelService: channel.service as string,
        clientName: Client.PublishWeb,
        ...organization?.commonTrackingProperties,
      })
    }
    dismissModal()
  }

  const dialogDescription = isSuccessConnection
    ? channelIds.length > 1
      ? "We'll add recommended posting times to each channel to help you reach your goal."
      : "We'll add recommended posting times to your queue to help you reach your goal."
    : "We'll add recommended posting times to your queue if you don't have any to help you reach your goal."

  return (
    <Dialog open onOpenChange={handleOpenChange}>
      <Dialog.Content size="xlarge" className={styles.content}>
        <Dialog.CloseButton />
        <Dialog.Header className={styles.header}>
          {isSuccessConnection && (
            <Confetti
              className={styles.confetti}
              duration={5000}
              startDelay={300}
            />
          )}
          <div className={styles.avatarWrapper}>
            {channelIds?.length > 1 ? (
              <ChannelAvatar.Stack
                max={5}
                style={
                  {
                    '--avatar-stack-margin-left': '-28px',
                  } as React.CSSProperties
                }
              >
                {channels.map((channel) => (
                  <ChannelAvatar
                    key={channel.id}
                    channel={channel.service as ChannelType}
                    src={channel.avatar}
                    alt={channel.name}
                    size="large"
                    className={styles.avatar}
                  />
                ))}
              </ChannelAvatar.Stack>
            ) : (
              <ChannelAvatar
                channel={channel.service as ChannelType}
                src={channel.avatar}
                alt={channel.name}
                size="large"
                className={styles.avatar}
                style={
                  {
                    '--channel-avatar-border-width': '2px',
                  } as React.CSSProperties
                }
              />
            )}
            {isSuccessConnection && (
              <div className={styles.check}>
                <CheckIcon color="inverted" />
              </div>
            )}
          </div>
          <Dialog.Title size="large">
            {isSuccessConnection
              ? 'All Done! Get Started With a Posting Goal'
              : 'Stay Consistent With a Posting Goal'}
          </Dialog.Title>
          <Dialog.Description className={styles.description}>
            {dialogDescription}
          </Dialog.Description>
        </Dialog.Header>
        <form
          onSubmit={(e): void => {
            e.preventDefault()
            handleCreatePostingPlan()
          }}
        >
          <Dialog.Body className={styles.body}>
            {error && (
              <Notice variant="error" className={styles.error}>
                There was an error creating your posting goal. Please try again.
              </Notice>
            )}
            <RadioCard.Group
              value={selectedGoal}
              onValueChange={(value: string): void => setSelectedGoal(value)}
            >
              {POSTING_GOAL_OPTIONS.map((option) => (
                <RadioCard key={option.goal} value={option.goal.toString()}>
                  <Flex align="center" gap="md">
                    <div className={clsx(styles.goalFrequency, option.color)}>
                      {option.goal}x
                    </div>
                    <div>
                      <Heading size="small">{option.title}</Heading>
                      <Paragraph color="subtle">{option.description}</Paragraph>
                    </div>
                  </Flex>
                </RadioCard>
              ))}
            </RadioCard.Group>
          </Dialog.Body>
          <Dialog.Footer className={styles.footer}>
            <div className={styles.actionButtons}>
              <Button
                type="submit"
                size="large"
                variant="primary"
                disabled={!selectedGoal || loading}
                loading={loading}
                className={styles.action}
              >
                Commit to Posting Goal
              </Button>
              <Button
                type="button"
                onClick={handleRemindLater}
                size="large"
                variant="tertiary"
                className={styles.action}
              >
                {isSuccessConnection
                  ? 'Skip'
                  : remindLater
                  ? 'Remind Me Later'
                  : 'Cancel'}
              </Button>
            </div>
          </Dialog.Footer>
        </form>
      </Dialog.Content>
    </Dialog>
  )
}

export default PostingGoalConfiguration

function getChannelFromUrl(): string | null {
  const pathname = window.location.pathname
  return pathname.match(/\/channels\/([^/]+)/)?.[1] ?? null
}
