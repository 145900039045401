import { useAppSelector } from '~publish/legacy/store'
import { useChannels } from '~publish/components/PublishSidebar/useChannels'
import { useCurrentOrganization } from '~publish/legacy/accountContext'
import { selectUserChannelGroups } from '~publish/legacy/user/selectors'
import type { ChannelGroup } from '../ChannelGroups'
import { useSplitEnabled } from '@buffer-mono/features'

const MIN_CHANNELS_FOR_CHANNEL_GROUPS = 4

export const useChannelGroupsAccess = (): {
  canAccessChannelGroups: boolean
  hasEnoughChannels: boolean
  isGroupsReady: boolean
  channelGroups: ChannelGroup[]
} => {
  const { channels, loading } = useChannels()
  const organization = useCurrentOrganization()
  const channelGroups = useAppSelector(selectUserChannelGroups)
  const { isEnabled: isChannelGroupsEnabled } =
    useSplitEnabled('CT-channel-groups')
  const isGroupsReady = !loading && !!organization

  const hasEnoughChannels = channels.length >= MIN_CHANNELS_FOR_CHANNEL_GROUPS
  const hasChannelGroupsFeature = organization?.entitlements.includes(
    'channelGroupsInComposer',
  )
  const hasChannelGroups = channelGroups?.length > 0
  const canAccessChannelGroups =
    (hasChannelGroupsFeature && hasEnoughChannels && hasChannelGroups) ||
    isChannelGroupsEnabled

  return {
    channelGroups,
    canAccessChannelGroups,
    hasEnoughChannels,
    isGroupsReady,
  }
}
