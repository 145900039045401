import { toast } from '@buffer-mono/popcorn'
import { useCallback } from 'react'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'
import {
  DismissAllCommentsByChannelIdMutation,
  type DismissAllCommentsByChannelId,
} from './useDismissAll'

export enum DismissAllCommentsStatus {
  Processed = 'processed',
  Enqueued = 'enqueued',
}

export type UseDismissAllCommentByChannelIdResult = {
  dismissAllCommentsByChannelId: DismissAllCommentsByChannelId
  loading: boolean
}

/**
 * Hook to dismiss all comments in a channel
 *
 * @returns {UseDismissAllCommentByChannelIdResult} - The dismissAllCommentsByChannelId function
 *
 * @example
 *
 * const { dismissAllCommentsByChannelId } = useDismissAllCommentsByChannelId()
 *
 * const handleDismissAllCommentsByChannelId = async () => {
 *  await dismissAllCommentsByChannelId(channelId)
 * }
 *
 */
export const useDismissAllCommentsByChannelId =
  (): DismissAllCommentsByChannelId => {
    const [dismissAllMutation] = useTypedMutation(
      DismissAllCommentsByChannelIdMutation,
      (data) => data.dismissAllCommentsByChannelId,
      {
        successTypename: 'DismissAllCommentsSuccess',
        refetchQueries: ['Comments'],
      },
    )

    const dismissAllCommentsByChannelId = useCallback(
      async (channelId: string): Promise<void> => {
        if (!channelId) {
          throw new Error('Channel id is required')
        }

        try {
          const { data } = await dismissAllMutation({
            variables: { input: { channelId } },
          })

          if (data && data?.status === DismissAllCommentsStatus.Processed) {
            const dismissedCount = data?.affectedCommentCount || 0
            if (dismissedCount === 0) {
              toast.success("We couldn't find any comment to dismiss")
            } else {
              toast.success(
                `Successfully dismissed ${dismissedCount} comment${
                  dismissedCount === 1 ? '' : 's'
                }`,
              )
            }
          } else {
            toast.error('An error occurred while dismissing the comment')
          }
        } catch (error) {
          toast.error('An error occurred while dismissing the comment')
        }
      },
      [dismissAllMutation],
    )

    return dismissAllCommentsByChannelId
  }
