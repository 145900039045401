import React from 'react'
import { Banner } from '@buffer-mono/popcorn'
import { useUser } from '../../../../common/context/User'
import getTrialBannerCopy from '../../utils/getTrialBannerCopy'
import UpgradePathsTracking from '../../../../tracking/UpgradePaths'
import { getRemainingTrialDays } from '../../../../common/utils/user'
import { MODALS } from '../../../../common/types'
import { useModalManager } from '../../../../components/ModalManager/hooks/useModalManager'

const trackingPayload = {
  ctaButton: 'startSubscription',
  ctaView: 'banner',
  ctaLocation: 'navBar',
  ctaVersion: '1',
  upgradePathName: 'trialBanner-upgrade',
}

export const TrialBanner = (): JSX.Element => {
  const user = useUser()
  const { openModal } = useModalManager()
  const { trackCTAClicked } = UpgradePathsTracking(user)

  const daysRemaining = getRemainingTrialDays(user)
  const trialBannerString = getTrialBannerCopy({
    daysRemaining,
  })

  return (
    <Banner variant="info" data-testid="trial-banner">
      <Banner.Text>{trialBannerString}</Banner.Text>
      <Banner.Action
        onClick={(): void => {
          openModal({
            key: MODALS.planSelector,
            data: {
              cta: 'navBar-banner-startSubscription-1',
              upgradePathName: 'trialBanner-upgrade',
            },
          })
          trackCTAClicked(trackingPayload)
        }}
      >
        Start Subscription Now
      </Banner.Action>
    </Banner>
  )
}

export default TrialBanner
