import React from 'react'
import { Banner } from '@buffer-mono/popcorn'
import UpgradePathsTracking from '../../../../tracking/UpgradePaths'
import { useUser } from '../../../../common/context/User'
import { getBillingPageUrl } from '../../../../common/utils/urls'

const trackingPayload = {
  ctaButton: 'updatePaymentDetails',
  ctaView: 'banner',
  ctaLocation: 'navBar',
  ctaVersion: '1',
  upgradePathName: 'pastDue-upgrade',
}

const PaymentPastDueBanner = (): JSX.Element => {
  const user = useUser()
  const { trackCTAClicked } = UpgradePathsTracking(user)

  return (
    <Banner variant="critical" data-testid="payments-past-due-banner">
      <Banner.Text>
        <strong>ACTION REQUIRED:</strong> We had a problem charging your last
        payment. Please update your payment details to prevent losing access to
        your features.
      </Banner.Text>
      <Banner.Action
        onClick={(): void => {
          trackCTAClicked(trackingPayload)
          window.location.assign(getBillingPageUrl('update-payment-method'))
        }}
      >
        Update Payment Details
      </Banner.Action>
    </Banner>
  )
}

export default PaymentPastDueBanner
