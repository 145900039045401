import React, { useMemo } from 'react'
import type { RawTimeZone } from '@vvo/tzdb'

import {
  Avatar,
  Badge,
  ChannelIcon,
  Combobox,
  Flex,
  MapPinIcon,
  Text,
  Tooltip,
} from '@buffer-mono/popcorn'

import { CURRENT_TIME_ZONE } from '~publish/helpers/dateFormatters'

import { useChannels } from '../PublishSidebar/useChannels'

import styles from './TimeZoneSelectItem.module.css'

export function TimeZoneSelectItem({
  timezone,
}: {
  timezone: RawTimeZone
}): React.ReactElement {
  const { channels } = useChannels()
  const {
    name: id,
    rawOffsetInMinutes: longOffset,
    mainCities,
    group,
  } = timezone
  const city = id.split('/')[1].replace(/_/g, ' ') ?? mainCities[0]

  const channelsInTimezone = useMemo(
    () =>
      channels?.filter(
        ({ timezone }) => timezone === id || group.includes(timezone),
      ),
    [channels, id, group],
  ).reverse()

  const isBrowserTimezone = id === CURRENT_TIME_ZONE

  const tooltipContent = (
    <Flex direction="column" gap="xs">
      {isBrowserTimezone && (
        <Flex gap="xs" align="center">
          <MapPinIcon size="xsmall" />
          <Text>Browser detected timezone</Text>
        </Flex>
      )}
      {channelsInTimezone.slice(0, 5).map((channel) => (
        <Flex gap="xs" key={channel.id} align="center">
          <ChannelIcon
            type={channel.service}
            size="xsmall"
            style={{ color: 'currentColor' }}
          />
          {channel.name}
        </Flex>
      ))}
      {channelsInTimezone.length > 5 && (
        <Text size="sm">+{channelsInTimezone.length - 5} more...</Text>
      )}
    </Flex>
  )

  const TooltipWrapper = ({
    children,
    ...props
  }: React.ComponentPropsWithoutRef<typeof Tooltip>): React.ReactElement => {
    if (!isBrowserTimezone && channelsInTimezone.length === 0) {
      return <>{children}</>
    }
    return (
      <Tooltip {...props} side="left" sideOffset={50}>
        {children}
      </Tooltip>
    )
  }

  // Format offset like "GMT+8:00" or "GMT+5:30"
  const offset = useMemo(() => {
    const hours = Math.floor(Math.abs(longOffset) / 60)
    const minutes = Math.abs(longOffset) % 60
    const sign = longOffset >= 0 ? '+' : '-'
    return `GMT${sign}${hours}:${minutes.toString().padStart(2, '0')}`
  }, [longOffset])

  return (
    <Combobox.Item key={id} value={id}>
      <TooltipWrapper content={tooltipContent}>
        <Flex
          justify="between"
          align="center"
          gap="2xs"
          className={styles.fullWidth}
        >
          {city}{' '}
          <Text color="subtle" size="sm">
            ({offset})
          </Text>
          <Flex className={styles.badgesList} gap="sm" justify="end">
            {isBrowserTimezone && (
              <Badge size="xsmall" className={styles.browserBadge}>
                <MapPinIcon />
              </Badge>
            )}
            {channelsInTimezone.length > 0 && (
              <Avatar.Stack
                max={2}
                size="xsmall"
                className={styles.avatarStack}
              >
                {channelsInTimezone.map((channel) => (
                  <Avatar
                    alt={channel.service}
                    src={channel.avatar}
                    size="xsmall"
                    key={channel.id}
                  />
                ))}
              </Avatar.Stack>
            )}
          </Flex>
        </Flex>
      </TooltipWrapper>
    </Combobox.Item>
  )
}
