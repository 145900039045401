// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'uplo... Remove this comment to see the full error message
import uploadcare from 'uploadcare-widget'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'uplo... Remove this comment to see the full error message
import uploadcareTabEffects from 'uploadcare-widget-tab-effects'
import mime from 'mime/lite'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import ComposerStore from '~publish/legacy/composer/composer/stores/ComposerStore'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { store } from '~publish/legacy/store'
import { getDebugLogger } from '~publish/legacy/utils/getDebugLogger'
import { UploadTypes } from '~publish/legacy/constants'
import { gifsAllowed, ServiceCropMap, UploadcareSources } from './constants'
import { PostTypeStory } from '../post/constants'
import { UploadSource } from '@buffer-mono/uploader'
import { env } from '~publish/env'
import type { Uploader } from '@buffer-mono/uploader'
import type { Service } from '~publish/gql/graphql'
import intersection from 'lodash/intersection'

const debug = getDebugLogger('uploadcare')

// This is required because 'ideas' is not allowed in the tracking plan
const getChannel = (serviceId: string): string =>
  serviceId === 'ideas' ? 'multiple' : serviceId

const onUploadDone = <T extends 'edit' | 'bar'>(
  result: any,
  uploader: Uploader,
  onError: (error: { message: string }) => void,
  onUploadcareUploadFinished: () => void,
  isEdit: boolean,
  draftData: T extends 'edit'
    ? { url?: string; deleteCallback?: () => void }
    : undefined,
  serviceId: string,
  source: T,
  integration: string,
  context: string,
): void => {
  try {
    if (isEdit) {
      draftData?.deleteCallback?.()
    }
  } catch (e) {
    if (e instanceof Error) {
      debug(e.message, e)
    }
  }

  result
    .promise()
    // @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
    .done(async (response) => {
      try {
        const extension = mime.getExtension(response.mimeType)
        const filename =
          !extension || response.name.endsWith(`.${extension}`)
            ? response.name
            : `${response.name}.${extension}`

        const files = await uploader.addFilesFromUrls(
          { remoteUrl: response.cdnUrl, name: filename },
          UploadSource.integration(integration),
        )

        onUploadcareUploadFinished()

        const media = files.successful[0].type?.includes('image')
          ? 'photo'
          : 'video'

        BufferTracker.uploadcareUploadFinished({
          organizationId: selectCurrentOrganizationId(store.getState()),
          clientName: 'publishWeb',
          product: 'publish',
          channel: getChannel(serviceId),
          source,
          integration,
          media,
          context,
        })
      } catch (e) {
        if (e instanceof Error) {
          debug(e.message, e)
          onError({ message: e.message })
        }
      }
    })
    .fail((message: string) => {
      /**
       * If error message = 'upload', it means the file size is too big (over 100MB,
       * which is allowed by our plan). It's returned by Uploadcare servers, so we change
       * it to something more meaningful
       */
      if (message === 'upload') {
        message = 'File size should be less than 100MB'
      }

      try {
        onUploadcareUploadFinished()
        debug(message)
        onError({ message })
      } catch (e) {
        if (e instanceof Error) debug(e.message, e)
      }
    })
}

// @ts-expect-error TS(7006) FIXME: Parameter 'fileInfo' implicitly has an 'any' type.
const imagesAndVideosOnly = (fileInfo) => {
  if (
    fileInfo.mimeType !== null &&
    fileInfo.isImage === false &&
    !fileInfo.mimeType.includes('video')
  ) {
    throw new Error('Files other than videos and images are not supported.')
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'fileInfo' implicitly has an 'any' type.
const fileSizeStories = (fileInfo) => {
  if (
    fileInfo.size !== null &&
    fileInfo.size > 8 * 1024 * 1024 &&
    fileInfo.isImage === true
  ) {
    throw new Error('Image has to be less than 8MB.')
  }

  if (
    fileInfo.size !== null &&
    fileInfo.size > 50 * 1024 * 1024 &&
    fileInfo.mimeType !== null &&
    fileInfo.mimeType.includes('video')
  ) {
    throw new Error('Video has to be less than 50MB.')
  }

  if (
    fileInfo.size !== null &&
    fileInfo.size > 3 * 1024 * 1024 &&
    fileInfo.mimeType !== null &&
    fileInfo.mimeType.includes('image/gif')
  ) {
    throw new Error('GIF has to be less than 3MB.')
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'fileInfo' implicitly has an 'any' type.
const fileSize = (fileInfo) => {
  if (
    fileInfo.size !== null &&
    fileInfo.size > 10 * 1024 * 1024 &&
    fileInfo.isImage === true
  ) {
    throw new Error('Image has to be less than 10MB.')
  }

  if (
    fileInfo.size !== null &&
    fileInfo.size > 1024 * 1024 * 1024 &&
    fileInfo.mimeType !== null &&
    fileInfo.mimeType.includes('video')
  ) {
    throw new Error('Video has to be less than 1GB.')
  }

  if (
    fileInfo.size !== null &&
    fileInfo.size > 3 * 1024 * 1024 &&
    fileInfo.mimeType !== null &&
    fileInfo.mimeType.includes('image/gif')
  ) {
    throw new Error('GIF has to be less than 3MB.')
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'fileInfo' implicitly has an 'any' type.
const gifsNotAllowed = (fileInfo) => {
  if (fileInfo.mimeType !== null && fileInfo.mimeType.includes('image/gif')) {
    throw new Error('Gifs are not supported for this network.')
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'fileInfo' implicitly has an 'any' type.
const imagesOnlyAllowed = (fileInfo) => {
  if (
    fileInfo.mimeType !== null &&
    (fileInfo.mimeType.includes('video') || fileInfo.mimeType.includes('gif'))
  ) {
    throw new Error('For this network, we support only image upload.')
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'fileInfo' implicitly has an 'any' type.
const imagesAndGifsOnlyAllowed = (fileInfo) => {
  if (fileInfo.mimeType !== null && fileInfo.mimeType.includes('video')) {
    throw new Error('For this network, we support only image or gif upload.')
  }
}

type UploadCareOptions = {
  publicKey: string
  imagesOnly: boolean
  tabs: string
  previewStep: boolean
  effects: string
  crop?: string
}

export const getUploadCareEditOptions = ({
  serviceId,
  updateType,
}: {
  serviceId: Service | 'omni' | 'ideas'
  updateType?: string
}): {
  uploadcareStartOptions: {
    effects: string[]
  }
  uploadcareOptions: UploadCareOptions
} => {
  const uploadcareStartOptions = {
    effects: [
      'blur',
      'sharp',
      'grayscale',
      'crop',
      'rotate',
      'enhance',
      'mirror',
      'flip',
      'invert',
    ],
  }
  const uploadcareOptions = {
    publicKey: env.VITE_UPLOADCARE_KEY,
    imagesOnly: true,
    tabs: 'preview',
    previewStep: true,
    effects: 'all',
  } as UploadCareOptions

  if (serviceId === 'omni') {
    const enabledMaps = ComposerStore.getEnabledDrafts().map(
      (draft) => ServiceCropMap[draft.id],
    )

    const intersectedCrops = [
      ...intersection(...enabledMaps),
      ...ServiceCropMap.omni,
    ]
    uploadcareOptions.crop = [...new Set(intersectedCrops)].join(',')
  } else {
    uploadcareOptions.crop = ServiceCropMap[serviceId].join(',')

    if (updateType === PostTypeStory) {
      uploadcareOptions.crop += ',' + ServiceCropMap.instagramStory.join(',')
    }
  }

  return { uploadcareStartOptions, uploadcareOptions }
}

export const uploadcareValidators = {
  fileSize,
  gifsNotAllowed,
  imagesOnlyAllowed,
  imagesAndVideosOnly,
  imagesAndGifsOnlyAllowed,
}

export const getUploadCareIntegrationsOptions = ({
  serviceId,
  integration,
  updateType,
}: {
  serviceId: Service | 'omni' | 'ideas'
  integration: string
  updateType?: string
}): {
  uploadcareOptions: Omit<
    UploadCareOptions,
    'previewStep' | 'effects' | 'imagesOnly'
  >
  uploadcareStartOptions: undefined
} => {
  const uploadcareOptions = {
    publicKey: env.VITE_UPLOADCARE_KEY,
    inputAcceptTypes: 'image/*,video/*',
    tabs: integration,
    validators: [imagesAndVideosOnly],
  }

  if (updateType === PostTypeStory) {
    uploadcareOptions.validators.push(fileSizeStories)
  } else {
    uploadcareOptions.validators.push(fileSize)
  }

  if (!gifsAllowed.includes(serviceId)) {
    uploadcareOptions.validators.push(gifsNotAllowed)
  }

  if (serviceId === 'googlebusiness') {
    uploadcareOptions.validators.push(imagesOnlyAllowed)
  }

  if (serviceId === 'startPage') {
    uploadcareOptions.validators.push(imagesAndGifsOnlyAllowed)
  }

  return {
    uploadcareOptions,
    uploadcareStartOptions: undefined,
  }
}

const openUploadcareModal = <T extends 'edit' | 'bar'>(
  serviceId: Service | 'omni' | 'ideas',
  uploader: Uploader,
  onError: (error: { message: string }) => void,
  integration: string,
  onUploadcareUploadStarted: () => void,
  onUploadcareUploadFinished: () => void,
  source: T,
  draftData: T extends 'edit'
    ? { url?: string; deleteCallback?: () => void }
    : undefined,
  updateType = String(UploadTypes.MEDIA),
  context = 'publishComposer',
): void => {
  const isEdit = source === UploadcareSources.EDIT
  if (isEdit) {
    BufferTracker.uploadcareEditClicked({
      organizationId: selectCurrentOrganizationId(store.getState()),
      clientName: 'publishWeb',
      product: 'publish',
      channel: getChannel(serviceId),
      source,
      integration,
      context,
    })
  } else {
    BufferTracker.uploadcareIntegrationButtonClicked({
      organizationId: selectCurrentOrganizationId(store.getState()),
      clientName: 'publishWeb',
      product: 'publish',
      channel: getChannel(serviceId),
      source,
      integration,
      context,
    })
  }

  // 1. Init options
  const { uploadcareStartOptions = {}, uploadcareOptions } = isEdit
    ? getUploadCareEditOptions({ serviceId, updateType })
    : getUploadCareIntegrationsOptions({ serviceId, integration, updateType })

  // 2. Init Uploadcare
  uploadcare.start(uploadcareStartOptions)

  // 3: If edit enabled, register "Edit" tab
  if (isEdit) {
    uploadcare.registerTab('preview', uploadcareTabEffects)
  }

  // 5. Open dialog
  const dialog = uploadcare.openDialog(null, uploadcareOptions)
  BufferTracker.uploadcareModalOpened({
    organizationId: selectCurrentOrganizationId(store.getState()),
    clientName: 'publishWeb',
    product: 'publish',
    channel: getChannel(serviceId),
    source,
    integration,
    context,
  })

  // 6: If edit enabled, fetch the image to Uploadcare
  if (isEdit && draftData?.url) {
    dialog.addFiles('url', [
      [
        draftData.url,
        {
          source: 'url-tab',
        },
      ],
    ])
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
  dialog.done((result) => {
    onUploadcareUploadStarted()
    BufferTracker.uploadcareUploadStarted({
      organizationId: selectCurrentOrganizationId(store.getState()),
      clientName: 'publishWeb',
      product: 'publish',
      channel: getChannel(serviceId),
      source,
      integration,
      context,
    })
    onUploadDone(
      result,
      uploader,
      onError,
      onUploadcareUploadFinished,
      isEdit,
      draftData,
      serviceId,
      source,
      integration,
      context,
    )
  })

  // @ts-expect-error TS(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
  dialog.fail((result) => {
    debug('Failed uploadcare dialog', result)
  })
}

export { openUploadcareModal }
