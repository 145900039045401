import React from 'react'
import { Redirect, Link, useParams, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { motion } from 'motion/react'
import {
  ArrowRightIcon,
  Button,
  ChannelAvatar,
  EmptyState,
  Flex,
  LockIcon,
  MessageCircleHeartIcon,
  Skeleton,
  SkeletonText,
  Tabs,
  Text,
} from '@buffer-mono/popcorn'

import { graphql } from '~publish/gql'
import type {
  Channel,
  GetChannelInfoForCommentsQuery,
} from '~publish/gql/graphql'
import { PageLayout } from '~publish/components/PageLayout'
import { isFacebookGroup } from '~publish/helpers/channels'
import { ChannelError } from '~publish/components/ChannelError'
import { FeedbackWidget } from '~publish/components/FeedbackWidget'
import { commentsPage, type CommentsChannelView } from '~publish/legacy/routes'
import { getEngageUrl } from '~publish/components/CommentsSidebar/ChannelNav/helpers'
import { useCommentsConfig } from '~publish/pages/Comments/CommentsChannelConfigContext'

import { CommentsList } from '../CommentsList'
import { PostsList } from '../PostsList'
import { OverviewPage } from '../OverviewPage'
import styles from './CommentsChannel.module.css'
import { useFilters } from '../CommentsFilters/useFilters'
import { CommentsFilters } from '../CommentsFilters'
import { NewCommentsAvailableButton } from './NewCommentsAvailableButton'
import type { BaseCommentsFilters } from '../CommentsFilters/useFilters'

// TODO: confirm this query and fragment are correct
export const ChannelPage_Comments = graphql(/* GraphQL */ `
  fragment ChannelPage_Comments on Channel {
    accessLevel
    id
    avatar
    isDisconnected
    isLocked
    name
    metadata {
      ... on LinkedInMetadata {
        __typename
        shouldShowLinkedinAnalyticsRefreshBanner
      }
    }
    locationData {
      location
    }
    serverUrl
    service
    timezone
    type
  }
`)

export const GetChannelInfoForComments = graphql(/* GraphQL */ `
  query GetChannelInfoForComments($channelId: ChannelId!) {
    channel(input: { id: $channelId }) {
      ...ChannelPage_Comments
      accessLevel
      id
      avatar
      isLocked
      name
      locationData {
        location
      }
      serverUrl
      service
      timezone
      type
    }
  }
`)

export function ChannelPageTitle({
  channel,
}: {
  channel?: GetChannelInfoForCommentsQuery['channel'] | null
}): JSX.Element {
  if (!channel) {
    return <></>
  }

  const serverUrl = isFacebookGroup(channel) ? null : channel.serverUrl
  const secondaryChannelText = channel.locationData?.location ?? serverUrl

  return (
    <Flex direction="row" align="center" gap="md">
      <ChannelAvatar
        src={channel.avatar}
        alt={channel.service}
        channel={channel.service}
        size="medium"
      />
      <div>
        <Flex direction="row" align="center" gap="xs">
          <PageLayout.Title>{channel.name}</PageLayout.Title>
        </Flex>
        {secondaryChannelText && (
          <Text color="subtle">{secondaryChannelText}</Text>
        )}
      </div>
    </Flex>
  )
}

const ChannelLockedError = (): JSX.Element => {
  return (
    <EmptyState size="large" variant="warning">
      <EmptyState.Icon>
        <LockIcon />
      </EmptyState.Icon>
      <EmptyState.Heading>This channel is locked</EmptyState.Heading>
      <EmptyState.Description>
        Channel you are trying to access is locked. Likely it is because your
        account was downgraded.
      </EmptyState.Description>
      <EmptyState.Actions>
        <Button as={Link} to="/comments" size="large">
          <ArrowRightIcon />
          Go back to Community
        </Button>
      </EmptyState.Actions>
    </EmptyState>
  )
}

type CommentTab = 'overview' | 'comments'
const availableTabs: CommentTab[] = ['overview', 'comments']
const DEFAULT_TAB: CommentTab = 'comments'

export const CommentsChannel = (): JSX.Element => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tab = (searchParams.get('tab') as CommentTab) || DEFAULT_TAB
  const { channelId, view: viewParam } = useParams<{
    channelId: string
    view?: string
  }>()
  // Todo: Add setView back in when we have posts
  const [view] = React.useState<CommentsChannelView>(
    (viewParam as CommentsChannelView) ?? 'comments',
  )

  const { data, error } = useQuery(GetChannelInfoForComments, {
    variables: { channelId },
    fetchPolicy: 'cache-and-network',
  })

  const [
    { status, sortDirection, publishedAt, dateStart, dateEnd },
    {
      setStatus,
      setSortDirection,
      setPublishedAt,
      clearFilters,
      updateUrlParams,
      clearFiltersAndUpdateTab,
    },
  ] = useFilters()

  const filters = React.useMemo<BaseCommentsFilters>(
    () => ({ status, sortDirection, dateStart, dateEnd }),
    [status, sortDirection, dateStart, dateEnd],
  )

  // Validate and redirect for invalid tabs
  React.useEffect(() => {
    if (tab && !availableTabs.includes(tab as CommentTab)) {
      updateUrlParams({ tab: DEFAULT_TAB })
    }
  }, [tab, updateUrlParams])

  const handleTabChange = (newTab: CommentTab): void => {
    clearFiltersAndUpdateTab(newTab)
  }

  const {
    config: { isSupportedInComments, isSupportedInEngage },
  } = useCommentsConfig()

  if (error) {
    return <ChannelError error={error} />
  }

  const channel = data?.channel

  if (!channel) {
    return (
      <PageLayout>
        <PageLayout.Header>
          <PageLayout.HeaderRow>
            <Flex direction="row" align="center" gap="md">
              <Skeleton rounded width={48} height={48} />
              <Skeleton rounded width={100} height={20} />
            </Flex>
            <PageLayout.Actions>
              <Skeleton width={90} height={40} />
            </PageLayout.Actions>
          </PageLayout.HeaderRow>
          <PageLayout.HeaderRow>
            <Flex direction="row" align="center" gap="md">
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
            </Flex>
            <PageLayout.Actions>
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
            </PageLayout.Actions>
          </PageLayout.HeaderRow>
        </PageLayout.Header>
        <PageLayout.Container size="narrow">
          {/* <CommentsListSkeleton /> */}
          <p>Comments list skeleton</p>
        </PageLayout.Container>
      </PageLayout>
    )
  }

  if (channel?.isLocked) {
    return <ChannelLockedError />
  }

  if (channel.accessLevel === 'none') {
    return <Redirect to={commentsPage.route} />
  }

  if (!isSupportedInComments(channel)) {
    if (isSupportedInEngage(channel)) {
      window.location.href = getEngageUrl(channel.id)
      return <></>
    }

    return <Redirect to={commentsPage.route} />
  }

  // Todo: Add back in when we have posts
  /*
  const handleToggleGroupChange = (value: string): void => {
    if (!value) return
    if (value !== view) {
      setView(value as CommentsChannelView)
    }
  }
  */

  const PageLayoutMotion = motion.create(PageLayout)

  return (
    <PageLayoutMotion layoutScroll>
      <Tabs
        defaultValue={DEFAULT_TAB}
        value={tab}
        onChange={(tab): void => handleTabChange(tab as CommentTab)}
      >
        <PageLayout.Header>
          <PageLayout.HeaderRow>
            <ChannelPageTitle channel={channel} />
            <PageLayout.Actions>
              <FeedbackWidget id="comments-channel-1" source="comments">
                <Button variant="tertiary" size="large">
                  <MessageCircleHeartIcon />
                  Share Feedback
                </Button>
              </FeedbackWidget>
              {/*
              Todo: Add back in when we have posts
              tab === 'comments' && (
                <CommentsOrPostsViewToggle
                  channelId={channelId}
                  value={view}
                  handleToggleGroupChange={handleToggleGroupChange}
                />
              )}
              */}
            </PageLayout.Actions>
          </PageLayout.HeaderRow>
          <PageLayout.HeaderRow>
            <Tabs.TabList>
              {availableTabs.map((tab) => (
                <Tabs.Tab key={tab} value={tab}>
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </Tabs.Tab>
              ))}
            </Tabs.TabList>
            <PageLayout.Actions>
              {tab === 'comments' && (
                <CommentsFilters
                  status={status}
                  sortDirection={sortDirection}
                  publishedAt={publishedAt}
                  setStatus={setStatus}
                  setSortDirection={setSortDirection}
                  setPublishedAt={setPublishedAt}
                  clearFilters={clearFilters}
                  channelId={channelId}
                />
              )}
            </PageLayout.Actions>
            <NewCommentsAvailableButton
              channelId={channelId}
              filters={filters}
            />
          </PageLayout.HeaderRow>
        </PageLayout.Header>
        <PageLayout.Container
          as="main"
          size="narrow"
          className={styles.container}
        >
          <Tabs.Panel value="overview" className={styles.panel}>
            <OverviewPage channel={channel as Channel} />
          </Tabs.Panel>
          <Tabs.Panel value="comments" className={styles.panel}>
            {view === 'posts' ? (
              <PostsList channel={channel as Channel} />
            ) : (
              <CommentsList channel={channel as Channel} filters={filters} />
            )}
          </Tabs.Panel>
        </PageLayout.Container>
      </Tabs>
    </PageLayoutMotion>
  )
}
