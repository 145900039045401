import React from 'react'
import { Banner } from '@buffer-mono/popcorn'
import { useUser } from '../../../../common/context/User'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { getActiveProductFromPath } from '../../../../common/utils/getProduct'
import { isCanceledAtPeriodEnd } from '../../../../common/utils/user'
import useGetPortalSession from '../../../../common/hooks/useGetPortalSession'

const MissingBillingAddress = (): JSX.Element => {
  const user = useUser()
  const { openBillingPortal } = useGetPortalSession()

  const copy = isCanceledAtPeriodEnd(user)
    ? 'You do not have a billing address on file. Please add your info.'
    : 'You do not have a billing address on file. Please add your info to ensure your subscription remains in good standing.'

  return (
    <Banner variant="critical">
      <Banner.Text>
        <strong>ACTION REQUIRED:</strong> {copy}
      </Banner.Text>
      <Banner.Action
        onClick={(): void => {
          BufferTracker.billingPortalSessionCreated({
            organizationId: user.currentOrganization?.id || null,
            customerId: user.currentOrganization?.billing?.gateway?.gatewayId,
            ctaApp: getActiveProductFromPath(),
            ctaView: 'banner',
            ctaLocation: 'banner',
            ctaButton: 'addBillingAddress',
            ctaVersion: '1',
          })
          openBillingPortal(true)
        }}
      >
        Add Billing Address
      </Banner.Action>
    </Banner>
  )
}

export default MissingBillingAddress
