/**
 * Component that displays media suggestions and attachments
 */
import React from 'react'
import { Tooltip, type TooltipProps } from '@buffer-mono/popcorn'
import ComposerStore from '~publish/legacy/composer/composer/stores/ComposerStore'
import { MediaTypes } from '~publish/legacy/constants'
import { useIsIntegrationUploading } from '~publish/legacy/integrations-bar/state/useIsIntegrationUploading'
import { store, useAppSelector } from '~publish/legacy/store'
import { selectIsUploading } from '~publish/legacy/uploads/state/selectors'
import { ComposerUploader } from '~publish/legacy/uploads'
import AppStore from '../stores/AppStore'
import MediaAttachmentThumbnail from './media-attachment-thumbnail/MediaAttachmentThumbnail'
import ThumbnailButton from './ThumbnailButton'
import { type Draft, DraftMethods } from '../entities/Draft'
import { draftPropType } from './ComposerPropTypes'
import styles from './css/MediaAttachment.module.css'
import type { Gif, Media, Video } from '../entities/factories'
import { DocumentAttachment } from '~publish/legacy/composer/composer/components/document-attachment/DocumentAttachment'
import MediaAttachmentDragWrapper from './media-attachment-thumbnail/MediaAttachmentDragWrapper'
import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import type { Uploader } from '@buffer-mono/uploader'

export interface MediaAttachmentProps {
  draft: Draft
  uploader: Uploader
}

const TooltipWrapper = ({ ...props }: TooltipProps): JSX.Element => {
  if (!props.content) {
    return <>{props.children}</>
  }
  return <Tooltip {...props} />
}

const trackImageReordered = (
  draft: Draft,
  orderSource: number,
  orderTarget: number,
): void => {
  // when dropped, these properties are NaN and we don't want to track them
  if (isNaN(orderSource) || isNaN(orderTarget)) {
    return
  }

  const trackingParams = {
    organizationId: selectCurrentOrganizationId(store.getState()),
    clientName: 'publishWeb',
    product: 'publish',
    channelId: AppStore.getSelectedProfiles()[0].id,
    channel: draft.id,
    isDraft: AppStore.getMetaData().draftMode,
    isReminder: draft.isReminder ?? false,
    updateType: draft.updateType ?? 'post',
    mediaCount: draft.images.length,
    orderSource,
    orderTarget,
  }

  BufferTracker.composerMediaReordered(trackingParams)
}

const MediaAttachment = ({
  draft,
  uploader,
}: MediaAttachmentProps): JSX.Element | null => {
  const isUploading = useAppSelector((state) =>
    selectIsUploading(state, DraftMethods.getUploaderId(draft)),
  )

  const { isIntegrationUploading } = useIsIntegrationUploading()

  const shouldShowMediaAttachment =
    AppStore.getExpandedComposerId() === draft.id &&
    draft.hasMediaAttachmentEnabled()

  if (!shouldShowMediaAttachment) {
    return null
  }

  const areUploadsInProgress = isUploading || isIntegrationUploading

  const uploadNewButtonTooltipCopy = draft.isVerticalVideoOnlySupported()
    ? 'Upload a video'
    : 'Upload image or video'

  const uploadNewButtonUIClassNames: string[] = []
  if (areUploadsInProgress) {
    uploadNewButtonUIClassNames.push(styles.uploadNewButtonUIIsUploading)
  } else if (draft.tempImage) {
    uploadNewButtonUIClassNames.push(styles.uploadNewButtonUIWithTempImage)
  } else {
    uploadNewButtonUIClassNames.push(styles.uploadNewButtonUI)
  }

  const useSingleImageBox =
    (draft.isStoryPost() || draft.isFacebookStoryPost()) &&
    draft.hasNoMediaAttached()
  if (useSingleImageBox) {
    uploadNewButtonUIClassNames.push(styles.singleImageLayout)
  }
  if (useSingleImageBox && draft.tempImage) {
    uploadNewButtonUIClassNames.push(
      styles.uploadNewButtonUIWithTempImageSingleImg,
    )
  }

  uploadNewButtonUIClassNames.push('upload-button')

  const uploadNewButtonUIClassName = uploadNewButtonUIClassNames.join(' ')

  const thumbnailClassName = styles.thumbnail
  const hasSuggestedMedia =
    ComposerStore.getSuggestedMediaForDraft(draft, true).length > 0 &&
    !ComposerStore.getIsSuggestedMediaBoxHidden()
  const nonImageFirstMediaAttachmentClassName = hasSuggestedMedia
    ? styles.mediaAttachmentWithSuggestedMedia
    : ''
  const mediaAttachmentClassName = nonImageFirstMediaAttachmentClassName

  const mediaAttachmentClassNames = [
    styles.mediaAttachment,
    mediaAttachmentClassName,
    useSingleImageBox ? styles.singleImageLayout : '',
  ].join(' ')

  const CHANNELS_WITH_IMAGE_DESCRIPTION = [
    'twitter',
    'pinterest',
    'linkedin',
    'facebook',
    'startPage',
    'mastodon',
    'bluesky',
    'threads',
  ]
  const showImageDescription =
    CHANNELS_WITH_IMAGE_DESCRIPTION.includes(draft.id) &&
    Array.isArray(draft.images) &&
    draft.images.length > 0

  const isImage = (media: Media): boolean =>
    media.mediaType === MediaTypes.IMAGE

  // allow if draft feedback states that an account is set up for reminders
  const feedbackNotEnabled =
    draft.instagramFeedback.length === 1 &&
    draft.instagramFeedback.some((feedback) => feedback.code === 'NOT_ENABLED')

  const canAddUserTag =
    AppStore.getOrganizationsData().selected?.hasUserTagFeature &&
    draft.service.isInstagram() &&
    AppStore.getSelectedProfilesForService(draft.id).some(
      (profile) => profile.instagramDirectEnabled,
    ) &&
    /* don't allow user to add tag if post is a reminder though its ok if more than one
      ig profile is selected and one doesnt have direct scheduling enabled */
    (draft.instagramFeedback.length < 1 || feedbackNotEnabled) &&
    !draft.isStoryPost()

  const wrapperClassName = useSingleImageBox ? styles.singleImageWrapper : ''

  return (
    <div className={wrapperClassName}>
      <div className={mediaAttachmentClassNames}>
        {draft.hasImagesAttachment() &&
          draft.images.map((image, index) => (
            <MediaAttachmentDragWrapper
              className={thumbnailClassName}
              key={image.url}
              index={index}
              media={image}
              draft={draft}
              showImageDescription={showImageDescription}
              canAddUserTag={
                canAddUserTag && isImage(image) && !draft.isReminder
              }
              onDropMediaAttachment={(params: {
                dragIndex: number
                hoverIndex: number
              }): void => {
                const { dragIndex, hoverIndex } = params
                ComposerActionCreators.updateDraftMediaOrder(
                  draft.id,
                  dragIndex,
                  hoverIndex,
                )
                trackImageReordered(draft, dragIndex + 1, hoverIndex + 1)
              }}
              uploader={uploader}
            />
          ))}

        {draft.hasVideoAttachment() && (
          <div>
            <MediaAttachmentThumbnail
              draft={draft}
              className={thumbnailClassName}
              key={draft.video?.thumbnail}
              media={draft.video as Video}
              showImageDescription={showImageDescription}
              uploader={uploader}
            />
            <ThumbnailButton draft={draft} />
          </div>
        )}

        {draft.hasGifAttachment() && (
          <MediaAttachmentThumbnail
            draft={draft}
            className={thumbnailClassName}
            key={draft.gif?.url}
            media={draft.gif as Gif}
            showImageDescription={showImageDescription}
            uploader={uploader}
          />
        )}

        <DocumentAttachment className={thumbnailClassName} draft={draft} />

        {draft.canAttachMoreMedia() && (
          <TooltipWrapper
            content={
              !areUploadsInProgress ? uploadNewButtonTooltipCopy : undefined
            }
          >
            <div className={uploadNewButtonUIClassName}>
              <ComposerUploader draft={draft} uploader={uploader} />
            </div>
          </TooltipWrapper>
        )}
      </div>
    </div>
  )
}

MediaAttachment.propTypes = {
  draft: draftPropType.isRequired,
}

MediaAttachment.defaultProps = {}

export default MediaAttachment
