import React, { useCallback } from 'react'
import {
  Button,
  DropdownMenu,
  EllipsisVerticalIcon,
} from '@buffer-mono/popcorn'
import { useDismissAll } from '../hooks/useDismissAll'

type BulkActionsMenuProps = {
  channelId: string
}

export const BulkActionsMenu = ({
  channelId,
}: BulkActionsMenuProps): JSX.Element => {
  const { dismissAllByChannelId } = useDismissAll()

  const handleDismissAllComments = useCallback(async (): Promise<void> => {
    await dismissAllByChannelId(channelId)
  }, [dismissAllByChannelId, channelId])

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <Button size="medium" variant="tertiary">
          <EllipsisVerticalIcon />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content data-no-focus-lock>
        <DropdownMenu.Item onClick={handleDismissAllComments}>
          Dismiss all comments
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}
